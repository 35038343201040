import React from 'react';
import Logo from "../../assets/icons/logo.png"

const FooterBar = () => {
    return (
        <footer className='flex flex-col gap-10 justify-center items-center w-full p-10 mt-20'>
            <img className="md:w-20 w-[15%] bg-black" src={Logo} alt="" />
            <p className='text-sm text-white text-opacity-50 font-semibold'>Copyright @ Today with God 2023. | Privacy & Policy</p>
        </footer>
    )
}

export default FooterBar