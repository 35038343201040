import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./screens/login/LoginPage";
import HomePage from "./screens/home/HomePage";
import Curriculum from "./screens/curriculum/Curriculum";
import MyFavourites from "./screens/myFavourites/MyFavourites";
import Givings from "./screens/givings/Givings";
import Testimonials from "./screens/testimonials/Testimonials";
import VideoInfo from "./components/common/VideoInfo";
import SeeAll from "./components/common/SeeAll";
import MyProfile from "./components/profileMenu/MyProfile";
import MyDownloads from "./components/profileMenu/MyDownloads";
import GivingTwo from "./components/givings/GivingTwo";
import GivingPastList from "./components/givings/GivingPastList";
import About from "./components/profileMenu/About";
import LessonDetails from "./components/curriculum/LessonDetails";
import VideoPlayerContainer from "./components/VideoPlayer/VideoPlayerContainer";
import CategoryInfo from "./components/common/CategoryInfo";
import TestimonialInfo from "./components/common/TestimonialInfo";
import CurriculumDetails from "./components/curriculum/CurriculumDetails";
import CurriculumDeatailsNew from "./components/curriculum/CurriculumDeatailsNew";
import CurriculumDetailsNew2 from "./components/curriculum/CurriculumDetailsNew2";

function App() {
  return (
    <div className="web-app">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/curriculum" element={<Curriculum />} />
          <Route path="/favourites" element={<MyFavourites />} />
          <Route path="/givings" element={<Givings />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/videoInfo/:id" element={<VideoInfo />} />
          <Route path="/categoryInfo/:id" element={<CategoryInfo />} />
          <Route path="/testimonialInfo/:id" element={<TestimonialInfo />} />
          <Route path="/seeAll" element={<SeeAll />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/downloads" element={<MyDownloads />} />
          <Route path="/givings/comp" element={<GivingTwo />} />
          <Route path="/givings/past-list" element={<GivingPastList />} />
          <Route path="/about" element={<About />} />
          <Route path="/lesson-details/:id/:index" element={<LessonDetails />} />
          <Route path="/curriculum-details/:id" element={<CurriculumDetailsNew2 />} />
          <Route path="/video-player" element={<VideoPlayerContainer />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
