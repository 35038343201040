import React, { useEffect, useRef, useState } from "react";
import {
  BsPauseCircle,
  BsPauseCircleFill,
  BsPlayCircle,
  BsPlayCircleFill,
  BsX,
} from "react-icons/bs";
import CommonCarousel from "./CommonCarousel";
import ReactModal from "react-modal";

const HomePromiseNew = ({
  title,
  bgImage,
  hearGodsWord = "",
  readGodsWord,
  audioFile,
  promises,
}) => {
  const [playing, setPlaying] = useState(false);
  const [playTime, setPlayTime] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [activeItem, setActiveItem] = useState({
    title: title,
    hearGodsWord: hearGodsWord,
    readGodsWord: readGodsWord,
    image: bgImage,
    audio: audioFile,
  });

  console.log("activeItem", activeItem);
  const audioRef = useRef();

  const togglePlaying = () => {
    if (playing) {
      audioRef.current.pause();
      setPlaying(!playing);
    } else {
      audioRef.current.play();
      setPlaying(!playing);
    }
  };

  useEffect(() => {
    if (activeItem?.audio) {
      audioRef.current.pause();
      setPlaying(false);
    }
  }, [activeItem?.audio]);

  const getFormattedTime = (time) => {
    const minutes = !isNaN(time) ? Math.floor(time / 60) : 0;
    const seconds = !isNaN(time) ? Math.floor(time - minutes * 60) : 0;
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds} Min`;
  };

  const updatePlayTime = (e) => {
    setPlayTime(e.target.currentTime);
  };

  return (
    <div className="w-full pr-[2%]">
      <p className="text-xl text-white font-semibold w-full mb-4">Promise of the Day</p>
      <div className="flex gap-[3%]">
        <div
          style={{ backgroundImage: `url('${activeItem?.image}')` }}
          className="w-[50%] aspect-[16/12] bg-black rounded-2xl bg-cover bg-top bg-no-repeat cursor-pointer"
          onClick={() => {
            setShowImageModal(true);
          }}
        ></div>
        <div className="flex flex-col justify-between w-[50%]">
          <div>
            <p className="text-yellowish text-4xl font-semibold mb-3">{activeItem?.title}</p>
            <p className="text-xl text-white opacity-50 font-[300]">
              {activeItem?.hearGodsWord?.length <= 500 ? (
                activeItem?.hearGodsWord
              ) : (
                <>
                  {activeItem?.hearGodsWord?.substring(0, 500)}{" "}
                  <span
                    onClick={() => setShowContentModal(true)}
                    className="text-yellow-500 font-medium opacity-100 text-opacity-100 cursor-pointer"
                  >
                    More
                  </span>
                </>
              )}
            </p>
          </div>
          <div className="mb-[5%]">
            <p className="text-yellowish font-semibold mb-4">Hear Gods Word</p>
            <div className="flex gap-[3%] items-center">
              <button onClick={togglePlaying}>
                {!playing ? (
                  <BsPlayCircleFill color="#EFBC51" className="w-[80px] h-[80px]" />
                ) : (
                  <BsPauseCircleFill color="#EFBC51" className="w-[80px] h-[80px]" />
                )}
              </button>
              <div className="w-full h-[5px] bg-white bg-opacity-50 rounded-full relative">
                <div className="absolute top-2 left-0 text-white text-opacity-50">
                  {getFormattedTime(playTime)}
                </div>
                <div className="absolute top-2 right-0 text-white text-opacity-50">
                  {getFormattedTime(audioRef?.current?.duration ?? 0)}
                </div>
              </div>
            </div>
            <audio
              src={activeItem?.audio}
              ref={audioRef}
              key={activeItem?.audio}
              loop
              onTimeUpdate={updatePlayTime}
            />
          </div>
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001, // Adjust this value as needed
          },
        }}
        className={
          "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
        }
        isOpen={showContentModal}
        onRequestClose={() => {
          setShowContentModal(false);
        }}
      >
        <button
          className="absolute left-4 top-4"
          onClick={() => {
            setShowContentModal(false);
          }}
        >
          <BsX size={40} color="white" />
        </button>
        <div className="h-[80vh] w-6/12 bg-black rounded-xl flex justify-center items-center cursor-pointer relative">
          <div className="absolute bottom-0 p-10 w-full h-full object-cover rounded-xl flex flex-col">
            <p className="text-yellowish text-4xl font-semibold mb-5 ">{activeItem?.title}</p>
            <p className="text-white text-lg w-full mb-5 text-opacity-60 flex-1 overflow-y-auto no-scrollbar">
              {activeItem?.hearGodsWord}
            </p>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001, // Adjust this value as needed
          },
        }}
        className={
          "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
        }
        isOpen={showImageModal}
        onRequestClose={() => setShowImageModal(false)}
      >
        <button className="absolute left-4 top-4" onClick={() => setShowImageModal(false)}>
          <BsX size={40} color="white" />
        </button>
        <div className="h-[80vh] w-9/12 rounded-xl flex justify-center items-center cursor-pointer relative">
          <div className="absolute bottom-0 max-w-fit h-full rounded-xl">
            <img src={bgImage} className="h-full" alt="promise_of_the_day" />
          </div>
        </div>
      </ReactModal>
      {promises && promises.length > 0 && (
        <CommonCarousel
          data={promises}
          seeAll={false}
          dataTitle={false}
          perViewImages={5}
          imageType={"landscape"}
          showTitle={true}
          showItemTitle={true}
          isPromise={true}
          promiseClickHandler={(item) => setActiveItem(item)}
        />
      )}
    </div>
  );
};

export default HomePromiseNew;
