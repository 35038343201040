import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    const language = localStorage.getItem("language_preferred");
    if (token) {
      request.headers.setAuthorization("Bearer " + token);

      if (request?.url === "/uploadAsset") {
        request.headers["Content-Type"] = "multipart/form-data";
      }
    }
    request.data = {
      ...request.data,
      mobile: false,
      language_preferrence: language || "en",
    };
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.message?.includes("jwt expired"))
      localStorage.removeItem("token");
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
