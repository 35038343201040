import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import award from "../../assets/images/certificate.png";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch } from "react-redux";
import { getProfileAction } from "../../store/Home/HomeAction";

const AchievementsCarousel = ({ data, perViewImages = 5, user }) => {
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (0.2 + perViewImages / 10);
  const [currentItem, setCurrentItem] = useState();
  const [showModal, setShowModal] = useState(false);

  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001,
      padding: 0, // Adjust this value as needed
    },
  };

  const generatePDF = () => {
    const input = pdfContentRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  return (
    <div className={"mt-4"}>
      <Carousel
        className="p-1"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div key={index} className={"gap-[1%] flex w-[100%]"}>
            {data
              ?.slice(index * perViewImages, (index + 1) * perViewImages)
              .map((item, i) => (
                <div
                  onClick={() => {
                    setCurrentItem(item);
                    setShowModal(true);
                  }}
                  style={{
                    width: widthOfImage + "%",
                  }}
                  className={`cursor-pointer bg-no-repeat bg-cover bg-top rounded-lg aspect-video bg-black flex flex-col justify-center items-center`}
                >
                  <div
                    style={{ backgroundImage: `url(${award})` }}
                    className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
                  ></div>
                  <p className="text-[10px] text-yellowish">Congratulations</p>
                  <p className="text-xs text-white">
                    {item.title?.length < 20
                      ? item.title
                      : item.title.substring(0, 20) + ".."}
                  </p>
                </div>
              ))}
          </div>
        ))}
      </Carousel>
      <ReactModal
        style={customStyles}
        isOpen={showModal}
        className={"overflow-hidden"}
        onRequestClose={() => setShowModal(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <button
            className="absolute top-5 right-5"
            onClick={() => setShowModal(false)}
          >
            <MdCancel color="#EFBC51" size={30} />
          </button>
          <div className="aspect-video rounded-2xl bg-black h-1/2 flex justify-center items-center flex-col">
            <div
              style={{ backgroundImage: `url(${award})` }}
              className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
            ></div>
            <p className="text-xl text-yellowish">Congratulations</p>
            <p className="text-2xl text-white">
              {currentItem?.title?.length < 20
                ? currentItem?.title
                : currentItem?.title?.substring(0, 20) + ".."}
            </p>
          </div>
          <button
            onClick={generatePDF}
            className="bg-yellowish px-6 py-4 text-xl font-semibold rounded-xl"
          >
            Download Certificate
          </button>
        </div>
        <div
          className="flex flex-col justify-start items-center "
          ref={pdfContentRef}
          style={{
            width: "595.28px",
            height: "842px",
            color: "black",
            background: "white",
          }}
        >
          <p className="font-bold text-2xl mb-4">
            Hello {user?.firstName} {user?.lastName}
          </p>
          <p>
            Congratulations on successfully completion of {currentItem?.title}{" "}
          </p>
        </div>
      </ReactModal>
    </div>
  );
};

export default AchievementsCarousel;
