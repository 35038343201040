import * as actionTypes from "../ActionTypes";

export const addCurriculum = (payload) => {
  return {
    type: actionTypes.ADD_GLOBAL_CURRICULUM,
    payload: payload,
  };
};

export const setCurriculum = (payload) => {
  return {
    type: actionTypes.SET_GLOBAL_CURRICULUM,
    payload: payload,
  };
};

export const addVideos = (payload) => {
  return {
    type: actionTypes.ADD_GLOBAL_VIDEOS,
    payload: payload,
  };
};

export const setVideos = (payload) => {
  return {
    type: actionTypes.SET_GLOBAL_VIDEOS,
    payload: payload,
  };
};

export const addTestimonial = (payload) => {
  return {
    type: actionTypes.ADD_GLOBAL_TESTIMONIALS,
    payload: payload,
  };
};

export const setTestimonial = (payload) => {
  return {
    type: actionTypes.SET_GLOBAL_TESTIMONIALS,
    payload: payload,
  };
};

export const addCategory = (payload) => {
  return {
    type: actionTypes.ADD_GLOBAL_CATEGORY,
    payload: payload,
  };
};

export const setCategory = (payload) => {
  return {
    type: actionTypes.SET_GLOBAL_CATEGORY,
    payload: payload,
  };
};
