import React, { useRef, useState } from "react";
import { BsArrowLeft, BsHeart, BsPlayCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import ReactModal from "react-modal";

const CategoryVideoModel = ({ isOpen, onRequestClose, data }) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001, // Adjust this value as needed
    },
  };

  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const togglePlay = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying((prev) => !prev);
    } else {
      videoRef.current.play();
      setPlaying((prev) => !prev);
    }
  };

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <ReactModal
      style={customStyles}
      className={
        "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <button
        className="absolute left-4 top-4"
        onClick={() => {
          onRequestClose();
          setPlaying(false);
        }}
      >
        <BsArrowLeft size={40} color="white" />
      </button>
      <div
        className="h-[90vh] bg-black rounded-xl flex justify-center items-center cursor-pointer relative"
        onClick={togglePlay}
      >
        {!playing && (
          <div className="absolute bottom-0 p-5 w-full">
            <p className="text-xs text-white text-opacity-70  p-2">{data?.description}</p>
          </div>
        )}
        <button
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          onClick={togglePlay}
        >
          {!loading && !playing && (
            <BsPlayCircleFill size={100} className="opacity-80 text-yellowish" />
          )}
        </button>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div className="text-white">Loading...</div>
          </div>
        )}
        <video
          ref={videoRef}
          className="h-full object-cover rounded-xl"
          src={data?.video}
          onLoadedData={handleLoadedData}
        />
      </div>
    </ReactModal>
  );
};

export default CategoryVideoModel;
