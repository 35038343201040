import React, { useState } from "react";
import Navbar from "./Navbar";
import HeaderBar from "./HeaderBar";

import BookOfLuke from "../../assets/images/BookOfLuke.png";
import {
  ArrayOfImgs,
  ArrayOfImgs13,
  ArrayOfImgs3,
} from "../../constants/NavbarConstants";
import SmallCarousel from "../home/SmallCarousel";
import FooterBar from "./FooterBar";
import Save from "../../assets/icons/Save.svg";
import Message from "../../assets/icons/Message.svg";
import { useLocation, useNavigate } from "react-router-dom";

const SeeAll = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const Data = location?.state?.data;
  const Title = location?.state?.title;
  const BackTo = location?.state?.Backto;
  const [activeTab, setActiveTab] = useState(BackTo);

  const handleNavigate = (type, id, index) => {
    console.log(type, "type1221");

    if (type === "videos") {
      navigate(`/videoInfo/${id}`, { state: { _id: id } });
    } else if (type === "category") {
      navigate(`/categoryInfo/${id}`, { state: { _id: id } });
    } else if (type === "testimonial") {
      navigate(`/testimonialInfo/${id}`, { state: { _id: id } });
    } else {
      navigate(`/lesson-details/${id}/${index}`, {
        state: { _id: id },
      });
    }
  };

  const handleTab1 = () => {
    setActiveTab(BackTo);
  };

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          title1={BackTo}
          back={true}
          active={activeTab}
          tabValues={[BackTo]}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-5">
          {Title && (
            <p className="px-6 text-white font-semibold text-xl">{Title}</p>
          )}
          <div className="grid grid-cols-5 p-6 pt-0 gap-6">
            {Data.map((item, index) => (
              <div
                className="flex flex-col gap-3 cursor-pointer"
                onClick={() => handleNavigate(item?.type, item?._id, index)}
              >
                <img
                  src={item.image}
                  className="w-full aspect-[16/9] rounded-lg object-cover"
                />
                <p className="text-white font-bold">
                  {item?.title?.length < 20
                    ? item?.title
                    : item?.title?.substring(0, 20) + ".."}
                </p>
                <p className="text-white text-opacity-50 text-sm">
                  {item?.subtitle?.length < 100
                    ? item?.subtitle
                    : item?.subtitle?.substring(0, 100) + ".."}
                </p>
              </div>
            ))}
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default SeeAll;
