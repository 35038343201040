import React, { useState } from 'react'
import FooterBar from '../common/FooterBar';
import HeaderBar from '../common/HeaderBar';
import Navbar from '../common/Navbar';
import { logo } from '../../constants/NavbarConstants';

const About = () => {
  const [activeTab, setActiveTab] = useState('About TWG');

  const handleTab1 = () => {
    setActiveTab('About TWG');
  }
  return (
    <div className='flex w-full overflow-hidden'>
      <Navbar active={"My Downloads"} />
      <div className='ml-[104px] flex flex-col w-full'>
        <HeaderBar
          popCancle={true}
          title1={'About TWG'}
          title2={''}
          active={activeTab}
          back={true}
          handleTab1={handleTab1}
        />
        <div className='flex flex-col bg-black h-full overflow-y-auto w-full items-center px-6 gap-6' >
          <img className='my-10 h-[25vh]' src={logo} />
          <p className='text-white text-opacity-50'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
          </p>
          <p className='text-white text-opacity-50'>
            It is a long established fact that a reader will be distracted by the readable content of a page <span className='text-yellow-500'>“Today with God”</span> when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
          </p>
          <p className='text-white text-opacity-50'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
          </p>
          <FooterBar />
        </div>
      </div>

    </div>
  )
}

export default About