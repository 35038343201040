import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import { FaPlay, FaStop } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { PiFileVideoFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { uploadAssestsAction } from "../../store/Home/HomeAction";
import { FaImage } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import myImage from "../../assets/images/BookOfLuke.png";
import Sample from "../../assets/images/sample.mp4";
import ReactPlayer from "react-player";
import { MdNavigateNext } from "react-icons/md";
import TextInputField from "../common/TextInputField";
import DropdownField from "../common/DropDown";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker from "react-datetime-picker";
import { testimonialSaveAction } from "../../store/Testimonial/TestimonialAction";
import Toastiy from "../common/Toastiy";
import {
  getCategoriesAction,
  saveCategoriesTestimoyAction,
} from "../../store/Categories/CategoriesActions";

const videoConstraints = {
  width: 400,
  height: 800,
  facingMode: "user",
};

const WebcamComponent = ({ setShowRecording }) => {
  const initialState = {
    testimonial_name: "",
    testimonial_description: "",
    keep_me_annonymous: 1,
    keep_my_location_private: 1,
    testimony_date: "",
    category: "",
    language: "en",
  };

  const { languageHeadings } = useSelector((state) => state.languageReducer.data);
  const webcamRef = useRef(null);
  const [page, setPage] = useState(1);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [currentUrl, setCurrentUrl] = useState();
  const [currentImage, setCurrentImage] = useState();
  const [testimonialValues, setTestimonialValues] = useState(initialState);
  const [categories, setCategories] = useState([]);

  const handleSave = () => {
    dispatch(
      testimonialSaveAction({
        apiPayloadRequest: {
          testimonial_name: testimonialValues.testimonial_name,
          testimonial_description: testimonialValues.testimonial_description,
          testomonial_rec_videoUrl: currentUrl,
          testomonial_rec_tumbnailUrl: currentImage,
          keep_me_annonymous: parseInt(testimonialValues.keep_me_annonymous),
          keep_my_location_private: parseInt(testimonialValues.keep_my_location_private),
          // testimony_date: testimonialValues.testimony_date,
          testimony_date: new Date().toISOString(),
          language: testimonialValues.language,
        },
        callback: (res) => {
          if (res.type === 1) {
            dispatch(
              saveCategoriesTestimoyAction({
                apiPayloadRequest: {
                  _id: testimonialValues.category,
                  testimonyObjId: res?.data[0]?._id,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    setShowRecording((prev) => !prev);
                    Toastiy("Testimonial Saved Successfully!", 1);
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const byteString = atob(imageSrc.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });

    // const formData = new FormData();

    // formData.append("file", file);

    dispatch(
      uploadAssestsAction({
        // apiPayloadRequest: formData,
        apiPayloadRequest: { file: file },
        callback: (res) => {
          setCurrentImage(res?.url);
        },
      })
    );

    console.log(imageSrc, "imageSrc");
  }, [webcamRef]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setStartUpload(true);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (startUpload && recordedChunks.length > 0) {
      handleUpload();
      setStartUpload(false);
    }
  }, [startUpload, recordedChunks]);

  const handleUpload = () => {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    const file = new File([blob], "react-webcam-stream-capture.mp4", {
      type: "video/mp4",
    });

    // const formData = new FormData();
    // formData.append("file", file);

    dispatch(
      uploadAssestsAction({
        // apiPayloadRequest: formData,
        apiPayloadRequest: { file: file },
        callback: (res) => {
          setCurrentUrl(res?.url);
          setRecordedChunks([]);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      getCategoriesAction({
        callback: (res) => {
          if (res?.type === 1) {
            res?.data?.map((item) => {
              setCategories((prev) => [
                ...prev,
                {
                  label: item.category_name,
                  value: item._id,
                },
              ]);
            });
          }
        },
      })
    );
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="bg-black overflow-hidden"
    >
      <button
        style={{
          position: "absolute",
          top: "2%",
          right: "2%",
        }}
        className="text-red-200"
        onClick={() => {
          setShowRecording((prev) => !prev);
        }}
      >
        <ImCancelCircle size={25} />
      </button>
      {page === 1 && (
        <>
          <Webcam
            style={{
              height: "95%",
              borderRadius: "10px",
            }}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            mirrored={false}
          />

          {capturing ? (
            <button
              style={{
                position: "absolute",
                bottom: "5%",
              }}
              className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60"
              onClick={handleStopCaptureClick}
            >
              <FaStop size={20} />
            </button>
          ) : (
            <button
              style={{
                position: "absolute",
                bottom: "5%",
              }}
              className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60"
              onClick={handleStartCaptureClick}
            >
              <FaPlay size={20} />
            </button>
          )}
          {currentUrl && (
            <div className="flex flex-col items-center gap-2 rounded-md opacity-50 hover:opacity-100 transition-all duration-700 ease-in-out w-fit absolute bottom-[12%] -right-[5%]">
              <p className="text-white text-xs">Video Preview</p>
              <ReactPlayer url={currentUrl} playing={true} height={"360px"} loop={true} />
            </div>
          )}

          <div
            style={{
              position: "absolute",
              bottom: "5%",
              left: "2%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentImage && (
              <div className="flex flex-col gap-2 justify-center items-center  rounded-md opacity-50 hover:opacity-100 transition-all duration-700 ease-in-out">
                <p className="text-white text-xs">Image Preview</p>
                <img src={currentImage} alt={"Thumbnail"} height={"250px"} width={"200px"} />
              </div>
            )}

            <div className="flex gap-4 items-center">
              <button
                className="text-red-200 p-4 rounded-full  bg-red-800 bg-opacity-60 flex gap-2 items-center text-xs font-semibold hover:bg-opacity-80 transition-all ease-in-out duration-500"
                onClick={capture}
              >
                <FaImage size={15} /> <p>Capture Thumbnail</p>
              </button>
              <p className="text-white font-bold">or</p>
              <div className="upload-btn-wrapper cursor-pointer">
                <button className="text-red-200 p-4 rounded-full  bg-red-800 bg-opacity-60 flex gap-2 items-center text-xs font-semibold hover:bg-red-700 transition-all ease-in-out duration-500">
                  <RiImageAddFill size={16} /> <p className="cursor-pointer">Upload Thumbnail</p>
                </button>
                <input
                  type="file"
                  onChange={(e) => {
                    const { files } = e.target;
                    // const formData = new FormData();
                    // formData.append("file", files[0]);

                    dispatch(
                      uploadAssestsAction({
                        // apiPayloadRequest: formData,
                        apiPayloadRequest: { file: files[0] },
                        callback: (res) => {
                          setCurrentImage(res?.url);
                        },
                      })
                    );
                  }}
                  name="myfile"
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {
        // currentImage && currentUrl &&
        page === 1 && (
          <button
            className="text-yellow-200 p-4 px-6 pr-3 rounded-lg font-bold bg-yellow-700 bg-opacity-60 text-xs flex gap-1 items-center hover:bg-opacity-80 transition-all ease-in-out duration-500 absolute bottom-[2%] right-[2%]"
            onClick={() => setPage(2)}
          >
            NEXT <MdNavigateNext size={22} />
          </button>
        )
      }
      {page === 2 && (
        <div className="h-full w-[50%] flex flex-col justify-center items-center">
          <p className="text-2xl text-white font-bold mb-5">Testimonial Details</p>
          <div className="p-5 pt-[5%] bg-black rounded-lg grid grid-cols-4 gap-4">
            <div className="col-span-4">
              <TextInputField
                labelText={"Add a title"}
                name={"testimonial_name"}
                placeholder={"Enter Title"}
                value={testimonialValues.testimonial_name}
                handleInputChange={(e) => {
                  setTestimonialValues((prev) => ({
                    ...prev,
                    testimonial_name: e.target.value,
                  }));
                }}
              />
            </div>
            <DropdownField
              name={"keep_me_annonymous"}
              placeholder={"Display Your Username"}
              labelText={"Display Your Username"}
              options={[
                {
                  label: "Yes",
                  value: 1,
                },
                {
                  label: "No",
                  value: 0,
                },
              ]}
              handleInputChange={(e) => {
                setTestimonialValues((prev) => ({
                  ...prev,
                  keep_me_annonymous: e.target.value,
                }));
              }}
              value={testimonialValues.keep_me_annonymous}
            />
            <DropdownField
              name={"keep_my_location_private"}
              placeholder={"Display Your Country"}
              labelText={"Display Your Country"}
              options={[
                {
                  label: "Yes",
                  value: 1,
                },
                {
                  label: "No",
                  value: 0,
                },
              ]}
              handleInputChange={(event) => {
                console.log(event, "event");
                setTestimonialValues((prev) => ({
                  ...prev,
                  keep_my_location_private: event.target.value,
                }));
              }}
              value={testimonialValues.keep_my_location_private}
            />
            <DropdownField
              name={"category"}
              placeholder={"Select category"}
              labelText={"Category"}
              options={categories}
              handleInputChange={(event) => {
                setTestimonialValues((prev) => ({
                  ...prev,
                  category: event.target.value,
                }));
              }}
              value={testimonialValues.category}
            />
            {/* <div>
              <p className="text-[12px] font-semibold text-greyish mb-[10px] pt-1">
                Testimony Date
              </p>
              <DateTimePicker
                className={"w-full"}
                format="dd/MM/yyyy"
                value={testimonialValues.testimony_date}
                onChange={(value) => {
                  setTestimonialValues((prev) => ({
                    ...prev,
                    testimony_date: value,
                  }));
                }}
              />
            </div> */}
            <DropdownField
              name={"language"}
              placeholder={"Language Recorded in"}
              labelText={"Language Recorded in"}
              options={languageHeadings?.map((item) => ({
                label: item?.language_name,
                value: item?.language_code,
              }))}
              handleInputChange={(event) => {
                setTestimonialValues((prev) => ({
                  ...prev,
                  language: event.target.value,
                }));
              }}
              value={testimonialValues.location}
            />
            <div className="col-span-4">
              <TextInputField
                labelText={"Caption your testimony"}
                name={"testimonial_description"}
                placeholder={"Enter Caption"}
                handleInputChange={(e) => {
                  setTestimonialValues((prev) => ({
                    ...prev,
                    testimonial_description: e.target.value,
                  }));
                }}
                value={testimonialValues.testimonial_description}
              />
            </div>
            {/* <div className="col-span-4">
              <TextInputField labelText={"Video URL"} disabled={true} value={currentUrl} />
            </div>
            <div className="col-span-4">
              <TextInputField labelText={"Thubanil URL"} disabled={true} value={currentImage} />
            </div> */}

            <div></div>
            <div></div>
            <div></div>
            <div className="flex justify-end mt-2">
              <button
                onClick={handleSave}
                className="px-8 py-2 rounded-lg text-xs font-bold text-black bg-yellow-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebcamComponent;
