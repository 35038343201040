import React from 'react'
import Navbar from '../../components/common/Navbar'
import HeaderBar from '../../components/common/HeaderBar'
import GiveImg from '../../assets/images/GiveImg.png';
import FooterBar from '../../components/common/FooterBar';
import { useNavigate } from 'react-router-dom';

const Givings = () => {
    const navigate = useNavigate();
    return (
        <div className='flex w-full overflow-hidden'>
            <Navbar active={"Givings"} />
            <div className='ml-[104px] flex flex-col w-full'>
                <HeaderBar popCancle={true}/>
                <div className='flex flex-col bg-black h-full overflow-y-auto w-full' >
                    <div className='w-full p-8'>
                        <div className='flex justify-center items-center rounded-3xl bg-[#EFBC51] h-[60vh]'>
                            <img src={GiveImg} />
                        </div>
                        <p className='pt-12 text-white text-lg opacity-50 text-center'>
                            We give because God first gave. Your support help us take the Holy unedited Word of God to the millions who need to hear the Good News of our Lord Jesus! Giving is our part, multiplication isHis, we give our 5 loaves and 2 fishes to the Master and He multiples it.
                        </p>
                        <p className='py-6 text-white text-lg opacity-50 text-center'>
                            Thank you for the willingness to be a part of his calling.
                        </p>
                        <div className='py-6 flex w-full justify-center'>
                            <button
                                className='p-2 rounded-md w-[300px] text-[15px] font-semibold border border-yellow-400 text-yellow-400'
                                onClick={() => navigate('/givings/comp')}
                            >
                                Give now
                            </button>
                        </div>
                    </div>
                    <FooterBar />
                </div>

            </div>
        </div>
    )
}

export default Givings