import Axios from "../../utils/axios";

export const getProfile = (payload) => {
  const URL = "/profile";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readLanguage = (payload) => {
  const URL = "/language/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readUserFav = (payload) => {
  const URL = "/user/getFav";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const userFav = (payload) => {
  const URL = "/user/fav/like";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getLanguageList = (payload) => {
  const URL = "/language/list";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getPickWhereLeft = (payload) => {
  const URL = "/user/getPickups";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getAchievements = (payload) => {
  const URL = "/user/getAchievement";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveUserProfile = (payload) => {
  const URL = "/user/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateUserProfile = (payload) => {
  const URL = "/user/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const layoutHomeScreen = (payload) => {
  const URL = "/layout/homescreen";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const layoutMaincarousel = (payload) => {
  const URL = "/layout/maincarousel";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const layoutTop10 = (payload) => {
  const URL = "/layout/top10";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readVideo = (payload) => {
  const URL = "/videos/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const uploadAssests = (payload) => {
  const URL = "/uploadAsset";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const getPromiseOfTheDay = (payload) => {
  const URL = "/promise/promiseOftheDay";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readPromise = (payload) => {
  const URL = "/promise/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveSupportRequest = (payload) => {
  const URL = "/support/request";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const savePrayerRequest = (payload) => {
  const URL = "/prayers/request";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
