import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import * as api from "./CategoriesAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* getCategoriesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesTestimoyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoriesTestimoy, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoriesTestimoyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoriesTestimoy, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteCategoriesTestimoyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.dateleCategoriesTestimoy, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* CategoriesWatcherSaga() {
  yield takeLatest(
    actionTypes.CATEGORIES_READ_REQUEST,
    getCategoriesRequestSaga
  );

  yield takeLatest(
    actionTypes.CATEGORIES_TESTIMONY_SAVE_REQUEST,
    saveCategoriesTestimoyRequestSaga
  );

  yield takeLatest(
    actionTypes.CATEGORIES_TESTIMONY_UPDATE_REQUEST,
    updateCategoriesTestimoyRequestSaga
  );

  yield takeLatest(
    actionTypes.CATEGORIES_TESTIMONY_DELETE_REQUEST,
    deleteCategoriesTestimoyRequestSaga
  );
}
