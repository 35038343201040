import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUDIENCE_URL,
        });
        setAccessToken(token);
      } catch (error) {
        console.error("Error fetching access token:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently]);

  return { accessToken, loading };
};

export const strippedString = (text) => text.replace(/(<([^>]+)>)/gi, "");

export const removeDuplicacyById = (data) => {
  const result = data?.filter(
    (value, index, self) => index === self.findIndex((t) => t?._id === value?._id)
  );
  return result;
};
