import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, EffectCoverflow, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { MdCancel, MdDownload } from "react-icons/md";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { strippedString } from "../../utils/Utils";
import { processVideoDownload } from "../../utils/VideoDownloadAndSave";

const LessonAndSlideComp = ({ lesson, activeItem, setActiveItem }) => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(true);
  const [showAnimation, setShowAnimation] = useState(true);
  const [topPopShow, setTopPopShow] = useState(false);
  const [bottomPopShow, setBottomPopShow] = useState(false);

  const handleNavigate = (item, index) => {
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, {
        state: { _id: item?._id },
      });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/lesson-details/${item._id}/${index}`, {
        state: { _id: item?._id },
      });
    }
  };

  const handleSlideChange = (swiper) => {
    console.log(swiper, "swiper");
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 600);
    }
  }, [showAnimation]);

  // useEffect(() => {
  //   setTopPopShow(false);
  //   setBottomPopShow(false);
  // }, [activeIndex]);

  console.log("lesson", lesson);

  const onVideoDownload = async (item) => {
    const record = {
      _id: item?._id,
      url: item?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link,
      title: item?.title,
      image: lesson?.lesson_tumbnail_urls?.[1]?.url_link,
      category: "curriculum",
    };
    await processVideoDownload(record);
  };

  return (
    <div className="px-6 pt-[3%]">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        draggable={true}
        navigation={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: "100%",
          //   scale: 0.8,
          depth: 50,
          modifier: 1,
          slideShadows: false,
        }}
        loop={false}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="flex justify-center"
        onSlideChange={handleSlideChange}
        observer={true}
        observeParents={true}
        parallax={true}
        onInit={(ev) => setSwiper(ev)}
      >
        {lesson?.slides?.map((slide, index) => (
          <SwiperSlide>
            <div className="flex justify-center w-full">
              <div className={`w-[85%]`}>
                {topPopShow && index === activeIndex && activeItem === lesson?.lesson_title && (
                  <div
                    className={`bg-black border-b-0 border border-white border-opacity-30 w-full aspect-[16/8] flex justify-center items-center relative ${
                      topPopShow ? "rounded-t-2xl overflow-hidden" : ""
                    } `}
                    onMouseOut={() => {
                      setActive(false);
                    }}
                    onMouseOver={() => {
                      setActive(true);
                    }}
                  >
                    <button className="absolute top-5 right-5 z-10 flex gap-5">
                      <BsArrowLeftCircle
                        size={25}
                        color="#EFBC51"
                        onClick={() => swiper.slidePrev()}
                      />
                      <BsArrowRightCircle
                        onClick={() => swiper.slideNext()}
                        size={25}
                        color="#EFBC51"
                      />
                      <MdCancel size={20} color="#EFBC51" onClick={() => setTopPopShow(false)} />
                      {slide?.slide_type?.[0]?.type_of_slide === "1" &&
                        slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link && (
                          <MdDownload
                            size={20}
                            color="#EFBC51"
                            onClick={() => onVideoDownload(slide)}
                          />
                        )}
                    </button>
                    {slide?.slide_type && slide?.slide_type?.length > 0 ? (
                      slide?.slide_type?.[0]?.type_of_slide === "0" ? (
                        slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link ? (
                          <div
                            className="w-full h-full bg-black bg-cover bg-center"
                            style={{
                              backgroundImage: `url('${slide?.slide_type?.[0]?.bg_asset_url[1]?.url_link}')`,
                            }}
                          ></div>
                        ) : (
                          <p className="text-white opacity-30 text-2xl">No Image URL.. </p>
                        )
                      ) : slide?.slide_type?.[0]?.type_of_slide === "1" ? (
                        slide?.slide_type?.[0]?.bg_asset_url[1]?.url_link ? (
                          <ReactPlayer
                            height={"100%"}
                            width={"100%"}
                            playing
                            url={slide?.slide_type?.[0]?.bg_asset_url[1]?.url_link}
                            controls={true}
                          />
                        ) : (
                          <p className="text-white opacity-30 text-2xl">No Video URL.. </p>
                        )
                      ) : slide?.slide_type?.[0]?.type_of_slide === "3" ? (
                        <div
                          className="w-full h-full font-bold text-xl"
                          style={{
                            backgroundImage: `url('${slide?.slide_type?.[0]?.bg_asset_url[1]?.url_link}')`,
                          }}
                        >
                          <div className="flex gap-5 p-[2%] h-[30%] items-center">
                            <div className="h-full w-[50px] bg-yellowish"></div>
                            <p className="text-5xl font-normal w-10/12">{lesson?.lesson_title}</p>
                          </div>
                          <div className="h-[70%] w-full flex items-center px-[2%]">
                            <div
                              className="w-full h-full overflow-auto no-scrollbar"
                              dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="w-full h-full font-bold text-xl"
                          style={{
                            background:
                              slide?.slide_type?.[0]?.type_of_slide === "2"
                                ? slide?.slide_type?.[0]?.slide_bg_color
                                : "white",
                          }}
                        >
                          <div className="flex gap-5 p-[2%] h-[30%] items-center">
                            <div className="h-full w-[50px] bg-yellowish"></div>
                            <p className="text-5xl font-normal w-10/12">{lesson?.lesson_title}</p>
                          </div>
                          <div className="h-[70%] w-full flex items-center px-[2%]">
                            <div
                              className="w-full h-full overflow-auto no-scrollbar"
                              dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                            ></div>
                          </div>
                        </div>
                      )
                    ) : (
                      <p className="text-white opacity-50 text-2xl">No Data...</p>
                    )}
                    {active &&
                      slide?.slide_type &&
                      slide?.slide_type?.length > 0 &&
                      (slide?.slide_type?.[0]?.type_of_slide === "0" ||
                        slide?.slide_type?.[0]?.type_of_slide === "1") && (
                        <div className="flex flex-col justify-end items-start w-full p-6 bg-gradient-to-b from-black from-[10%] absolute top-0">
                          <p className="text-white text-2xl font-bold mb-2 w-11/12">
                            {slide?.slide_title}
                          </p>
                          <p
                            className="text-white text-lg text-opacity-60 text-start whitespace-pre-line custom-content-description"
                            dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                          ></p>
                        </div>
                      )}
                  </div>
                )}
                <div className="w-full flex flex-col pb-8">
                  <div
                    className={`w-full min-w-[300px] aspect-[16/3] bg-cover bg-no-repeat relative overflow-hidden bg-black border border-white border-opacity-30 flex justify-startitems-center gap-4 p-4 ${
                      !(bottomPopShow && activeItem === lesson?.lesson_title) ? "rounded-b-2xl" : ""
                    } ${
                      !(bottomPopShow && activeItem === lesson?.lesson_title) &&
                      index === activeIndex + 1
                        ? "mt-4"
                        : ""
                    } ${
                      !(bottomPopShow && activeItem === lesson?.lesson_title) &&
                      index === activeIndex + 2
                        ? "mt-8"
                        : ""
                    } ${!topPopShow ? "rounded-t-2xl" : ""}`}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${lesson?.lesson_tumbnail_urls?.[1]?.url_link})`,
                      }}
                      className="h-full min-w-[26%] aspect-video rounded-xl overflow-hidden bg-black cursor-pointer bg-cover p-4 flex flex-col justify-end relative bg-top"
                      onClick={() => {
                        setActiveItem(lesson?.lesson_title);
                        setBottomPopShow((prev) => !prev);
                      }}
                    >
                      {/* {" "}
                      {!bottomPopShow && (
                        <>
                          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black "></div>
                          <div className="z-10">
                            <p className="text-white text-xl font-bold mb-2">
                              {lesson?.lesson_title?.length < 12
                                ? lesson?.lesson_title
                                : lesson?.lesson_title?.substring(0, 12) + ".."}
                            </p>
                            <p className="text-white text-xs opacity-60">
                              {lesson?.lesson_description?.length < 20
                                ? lesson?.lesson_description
                                : lesson?.lesson_description?.substring(0, 20) +
                                  "..."}
                            </p>
                          </div>
                        </>
                      )} */}
                    </div>
                    <div className="h-full flex flex-col justify-between min-w-[60%]">
                      <div className="flex flex-col flex-1 w-full overflow-ellipsis">
                        <p className="text-white font-bold text-3xl overflow-ellipsis text-nowrap text-start overflow-hidden w-[95%]">
                          {slide?.slide_title}
                        </p>
                        <p
                          style={{
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                          }}
                          className="text-white text-opacity-60 overflow-ellipsis overflow-hidden flex-1  custom-content-description line-clamp-2"
                          dangerouslySetInnerHTML={{
                            __html:
                              strippedString(slide?.slide_description)?.length > 250
                                ? strippedString(slide?.slide_description)?.substring(0, 250) + ".."
                                : strippedString(slide?.slide_description),
                          }}
                        ></p>
                      </div>
                      <div className="flex items-end">
                        <button
                          className="bg-yellowish md:text-xs lg:text-base py-2 px-6 w-fit rounded-lg font-semibold text-black"
                          onClick={() => {
                            setActiveItem(lesson?.lesson_title);
                            setTopPopShow((prev) => !prev);
                          }}
                        >
                          Watch Now
                        </button>
                      </div>
                    </div>
                  </div>
                  {bottomPopShow && activeItem === lesson?.lesson_title && (
                    <div className="bg-zinc-800 border-t-0 border-white border-opacity-30 border w-full rounded-b-2xl p-4">
                      <p className="text-white font-bold text-3xl">{slide?.slide_title}</p>
                      <p
                        className="!text-white opacity-60 mt-4 pb-2 custom-content-description max-h-[45vh] overflow-auto no-scrollbar"
                        dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LessonAndSlideComp;
