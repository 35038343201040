import React, { useState } from "react";
import SliderComp from "./SliderComp";
import { FaPlay } from "react-icons/fa";
import { BsDownload, BsFillVolumeDownFill } from "react-icons/bs";
import { GoScreenFull } from "react-icons/go";
import { MdFastForward, MdFastRewind } from "react-icons/md";
import { GiPauseButton } from "react-icons/gi";

const PlayControls = ({
  onVideoDownload,
  title,
  onPlayPause,
  onFastForward,
  onRewind,
  onVolumeChange,
  onVolumeSeekDown,
  volume,
  muted,
  playing,
  played,
  onToggleFullScreen,
  onSeek,
  elapsedTime,
  totalDuration,
  showDownload = false,
}) => {
  const [value, setValue] = useState(0);

  // const handleChange = (event) => {
  //     setValue(event.target.value);
  // }

  return (
    <div className="absolute w-full h-full flex flex-col justify-between">
      <div className="p-6 player-title" onClick={onPlayPause}>
        <p className="text-2xl font-bold text-white">{title}</p>
      </div>
      <div className="w-full h-full" onClick={onPlayPause}></div>

      <div className=" w-full player-controls p-6 bottom-0 flex flex-col gap-8">
        <SliderComp handleChange={onSeek} value={played * 100} step={1} />
        <div className="flex justify-between">
          <div className="flex items-center justify-center gap-8">
            {playing ? (
              <button onClick={onPlayPause}>
                <GiPauseButton color="white" size={"2em"} />
              </button>
            ) : (
              <button onClick={onPlayPause}>
                <FaPlay color="white" size={"2em"} />
              </button>
            )}
            <button>
              <BsFillVolumeDownFill color="white" size={"3.3em"} />
            </button>
            <SliderComp
              handleChange={onVolumeChange}
              value={volume * 100}
              small={true}
              vertical={true}
            />
            <p className="text-white">
              {elapsedTime}/{totalDuration}
            </p>
          </div>
          <div className="flex gap-8">
            <button onClick={onRewind}>
              <MdFastRewind color="white" size={"3em"} />
            </button>
            <button onClick={onFastForward}>
              <MdFastForward color="white" size={"3em"} />
            </button>
            <button onClick={onToggleFullScreen}>
              <GoScreenFull color="white" size={"3em"} />
            </button>
            {showDownload && (
              <button onClick={onVideoDownload}>
                <BsDownload color="white" size={"3em"} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayControls;
