import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectCoverflow } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const CoverEffectCarousel = ({ data, title, height, width }) => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  const handleNavigate = (item, index) => {
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, {
        state: { _id: item?._id },
      });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/lesson-details/${item._id}/${index}`, {
        state: { _id: item?._id },
      });
    }
  };

  const handleSlideChange = (swiper) => {
    console.log(swiper, "swiper");
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 600);
    }
  }, [showAnimation]);

  return (
    <div className="px-6 pt-[3%] relative">
      <p className="mb-6 text-lg font-semibold text-white">{title}</p>
      <Swiper
        onInit={(ev) => setSwiper(ev)}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 45,
          stretch: 900,
          depth: width * 0.105,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="flex justify-center"
        onSlideChange={handleSlideChange}
        breakpoints={{
          640: {
            coverflowEffect: {
              stretch: width * 0.74,
              depth: width * 0.105,
            },
          },
          1024: {
            stretch: width * 0.78,
            depth: width * 0.105,
          },
        }}
      >
        {data?.map((row, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center  p-12">
              <div
                className=" w-[60%] aspect-[16/9] bg-cover bg-top bg-no-repeat rounded-xl relative overflow-hidden"
                style={{
                  backgroundImage: `url(${row?.image})`,
                }}
              >
                <div
                  className={`absolute w-full h-full bg-gradient-to-r from-black ${
                    activeIndex === index
                      ? "to-transparent opacity-50"
                      : "to-black opacity-80"
                  } `}
                ></div>
                {activeIndex === index && (
                  <div
                    className={`${
                      showAnimation ? "btn-slide3" : ""
                    } absolute bottom-[10%] lg:w-[70%] md:w-[80%] px-[2%]`}
                  >
                    <p className="text-white lg:text-4xl md:text-base text-left font-bold drop-shadow-xl px-5 mb-4">
                      {row?.name?.length <= 15
                        ? row?.name
                        : row?.name?.substring(0, 15) + " .."}
                    </p>
                    <p className="text-white lg:text-xl md:text-base text-left drop-shadow-xl px-5 text-opacity-80">
                      {row?.subtitle?.length <= 110
                        ? row?.subtitle
                        : row?.subtitle?.substring(0, 108) + " .."}
                    </p>
                    <div className="mx-5 mt-4 w-full">
                      <button
                        onClick={() => {
                          handleNavigate(row, index);
                        }}
                        className="border border-yellowish hover:bg-yellowish hover:text-black px-[12%] lg:py-[10px] md:py-[8px] rounded-md text-yellowish font-semibold lg:text-base md:text-xs mb-1"
                      >
                        Watch Now
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="absolute top-[50%] z-10 opacity-20 hover:opacity-95 cursor-pointer"
        onClick={() => swiper.slidePrev()}
      >
        <FaCaretLeft size={50} color="white" />
      </div>
      <div
        className="absolute top-[50%] right-5 z-10 opacity-20 hover:opacity-95 cursor-pointer"
        onClick={() => swiper.slideNext()}
      >
        <FaCaretRight size={50} color="white" />
      </div>
    </div>
  );
};

export default CoverEffectCarousel;
