import React from "react";
import { Carousel } from "react-responsive-carousel";
import ImgSmall1 from "../../assets/images/smallCarousel/ImgSmall1.png";
import ImgSmall2 from "../../assets/images/smallCarousel/ImgSmall2.png";
import ImgSmall3 from "../../assets/images/smallCarousel/ImgSmall3.png";
import ImgSmall4 from "../../assets/images/smallCarousel/ImgSmall4.png";
import ImgSmall5 from "../../assets/images/smallCarousel/ImgSmall5.png";
import ImgSmall6 from "../../assets/images/smallCarousel/ImgSmall6.png";
import ImgSmall7 from "../../assets/images/smallCarousel/ImgSmall7.png";

import Top1 from "../../assets/images/Top10/Top1.svg";
import Top2 from "../../assets/images/Top10/Top2.svg";
import Top3 from "../../assets/images/Top10/Top3.svg";
import Top4 from "../../assets/images/Top10/Top4.svg";
import Top5 from "../../assets/images/Top10/Top5.svg";
import Top6 from "../../assets/images/Top10/Top6.svg";
import Top7 from "../../assets/images/Top10/Top7.svg";
import Top8 from "../../assets/images/Top10/Top8.svg";
import Top9 from "../../assets/images/Top10/Top9.svg";
import Top10 from "../../assets/images/Top10/Top10.svg";

import TopImg1 from "../../assets/images/Top10/TopImg1.png";
import TopImg2 from "../../assets/images/Top10/TopImg2.png";
import TopImg3 from "../../assets/images/Top10/TopImg3.png";
import TopImg4 from "../../assets/images/Top10/TopImg4.png";
import TopImg5 from "../../assets/images/Top10/TopImg5.png";
import TopImg6 from "../../assets/images/Top10/TopImg6.png";
import TopImg7 from "../../assets/images/Top10/TopImg7.png";
import { useNavigate } from "react-router-dom";
import { BsArrow90DegRight } from "react-icons/bs";
import { MdArrowRight } from "react-icons/md";

const imageArray = [Top1, Top2, Top3, Top4, Top5, Top6, Top7, Top8, Top9, Top10];

const Top10Carousel = ({
  data,
  seeAllValue = "See All",
  perViewImages = 4,
  linkTo,
  Backto = "Home",
}) => {
  const navigate = useNavigate();
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (2 + perViewImages / 10);

  const handleSeeAll = (title, data) => {
    navigate("/seeAll", {
      state: { title: title, data: data, Backto: Backto },
    });
  };

  const handleNavigate = (item, index) => {
    console.log("item", item);
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/curriculum-details/${item?._id}`, {
        state: { _id: item?._id },
      });
    }
  };

  console.log("top10 data", data);
  console.log("numberOfLists", numberOfLists);
  console.log("perViewImages", perViewImages);

  return (
    <div className="z-20 mt-8 pr-4">
      <div className="px-5 flex justify-between items-center">
        <p className="my-3 text-lg font-semibold text-white">Top 10</p>
        <p
          className="text-xs text-yellow-500 cursor-pointer"
          onClick={() => handleSeeAll("Top 10", data)}
        >
          {seeAllValue}
        </p>
      </div>
      <Carousel
        className="p-1 "
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={false}
        autoPlay={false}
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div key={index} className={"gap-[3%] flex w-[100%] pl-4 pb-8"}>
            {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <>
                <div
                  className={`relative cursor-pointer bg-no-repeat bg-cover bg-top rounded-lg aspect-video text-white bg-zinc-800 `}
                  onClick={() => {
                    handleNavigate(item, i);
                  }}
                  style={{
                    backgroundImage: `url('${item?.image}')`,
                    width: widthOfImage + "%",
                    zIndex: 1,
                  }}
                >
                  <img
                    src={imageArray[index * perViewImages + i]}
                    className={`absolute h-[75%] -bottom-[2px] ${
                      index * perViewImages + i === 4 ? "right-[40%]" : "right-[42%]"
                    }  `}
                  />
                  <p className="absolute -bottom-8 left-2 text-white text-xs overflow-hidden overflow-ellipsis text-start text-nowrap w-[95%]">
                    {item?.title}
                  </p>
                </div>
              </>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Top10Carousel;
