import React from "react";

const LoadingSlides = () => {
  return (
    <div className="mt-3">
      <div className="flex justify-between items-center px-1">
        <div className="w-[10%] h-5 bg-zinc-600 rounded animate-pulse"></div>
        <div className="w-[5%] h-5 bg-zinc-600 rounded animate-pulse"></div>
      </div>
      <div className="flex justify-start items-center gap-4 w-[100%] mt-4">
        <div className="aspect-video h-[25vh] rounded-lg bg-zinc-600 animate-pulse"></div>
        <div className="aspect-video h-[25vh] rounded-lg bg-zinc-600 animate-pulse"></div>
        <div className="aspect-video h-[25vh] rounded-lg bg-zinc-600 animate-pulse"></div>
        <div className="aspect-video h-[25vh] rounded-lg bg-zinc-600 animate-pulse"></div>
      </div>
    </div>
  );
};

export default LoadingSlides;
