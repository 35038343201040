import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { useDispatch } from "react-redux";
import SmallCarousel from "../home/SmallCarousel";
import TopSwiper2 from "./TopSwiper2";
import HeaderBar from "../common/HeaderBar";
import Navbar from "../common/Navbar";
import FooterBar from "../common/FooterBar";
import { ArrayOfImgs10, ArrayOfImgs9 } from "../../constants/NavbarConstants";
import {
  testimonialApprovedReadAction,
  testimonialByCategoryReadAction,
  testimonialInReviewReadAction,
  testimonialLikedReadAction,
  testimonialReadAction,
} from "../../store/Testimonial/TestimonialAction";
import {
  getAchievementsAction,
  setLoginHardBlocker,
  uploadAssestsAction,
} from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";
import Record from "../../assets/icons/record.svg";
import CommonCarousel from "../home/CommonCarousel";
import { deleteCategoriesTestimoyAction } from "../../store/Categories/CategoriesActions";
import LoadingSlides from "../common/LoadingSlides";
import { useAuth0 } from "@auth0/auth0-react";

const TestimonialComp = ({ setShowRecording }) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth0();

  const OPTIONS = {
    type: "video",
    fileName: "testimonial-video",
    fileType: "video/webm",
    mimeType: "video/webm;codecs=vp9",
    width: 1920,
    height: 1080,
    // ondataavailable: (blob) => console.log(blob, "blob"),
    // previewStream: (stream) => console.log(stream, "stream")
  };
  const recordWebcam = useRecordWebcam(OPTIONS);
  // const getRecordingFileHooks = async () => {
  //     const blob = await recordWebcam.getRecording();
  //     console.log({ blob });
  // };

  const initialTabs = ["Testimonials", "Your Testimonies"];
  const initialSeeAll = "See All";
  const checkToken = localStorage.getItem("token");
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");
  const [popCancle, setPopCancel] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [allTestimonials, setAllTestimonials] = useState([]);
  const [allLikedTestimonials, setAllLikeTestimonials] = useState([]);
  const [allInReviewTestimonials, setAllInReviewTestimonials] = useState([]);
  const [allApprovedTestimonials, setAllApprovedTestimonials] = useState([]);
  const [byCategoryHealingTestimonials, setByCategoryHealingTestimonials] = useState([]);
  const [byCategorySalvationTestimonials, setByCategorySalvationTestimonials] = useState([]);
  const [byCategoryDPTestimonials, setByCategoryDPTestimonials] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [showRecordedPreview, setShowRecordedPreview] = useState(false);

  console.log("allTestimonials", allTestimonials);

  useEffect(() => {
    dispatch(
      testimonialReadAction({
        callback: (res) => {
          const data = res?.data?.filter((item) => item.approval_status === 1);
          setAllTestimonials(data);
        },
      })
    );
    if (checkToken) {
      dispatch(
        testimonialLikedReadAction({
          callback: (res) => {
            const data = res?.data;
            setAllLikeTestimonials(data);
          },
        })
      );
      dispatch(
        testimonialInReviewReadAction({
          callback: (res) => {
            const data = res?.data;
            setAllInReviewTestimonials(data);
          },
        })
      );
      dispatch(
        testimonialApprovedReadAction({
          callback: (res) => {
            const data = res?.data;
            setAllApprovedTestimonials(data);
          },
        })
      );
      dispatch(
        testimonialByCategoryReadAction({
          callback: (res) => {
            console.log("res45", res);
            const dataHealing = res?.data.filter((item) => item.category_name === "Healing")[0]
              ?.documents;

            const dataSalvation = res?.data.filter((item) => item.category_name === "Salvation")[0]
              ?.documents;

            const dataDP = res?.data.filter((item) => item.category_name === "Divine Provision")[0]
              ?.documents;

            setByCategoryHealingTestimonials(dataHealing || []);
            setByCategorySalvationTestimonials(dataSalvation || []);
            setByCategoryDPTestimonials(dataDP || []);
          },
        })
      );
    }
  }, [currentCode, checkToken]);

  useEffect(() => {
    if (recordWebcam.status === "OPEN") {
      recordWebcam.start();
      setIsRecording(true);
    }
    if (recordWebcam.status === "PREVIEW") {
      setIsRecording(false);
      setShowRecordedPreview(true);
    }
  }, [recordWebcam.status]);

  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  const restructureData = (data) => {
    return data.map((item) => ({
      _id: item._id,
      image: item?.testomonial_rec_tumbnailUrl,
      title: item?.testimonial_name,
      subtitle: item?.subtitle,
      category: item?.categories[0]?._id || "",
    }));
  };

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"Testimonials"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={popCancle}
          tabValues={tabValues}
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={(code) => {
            setCurrectCode(code);
          }}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.testimonies) {
              const requiredLangData = titles?.testimonies;
              setTabsValues([
                requiredLangData.testimonies_lbl,
                requiredLangData.your_testimonies_lbl,
              ]);

              setSeeAllBtn(requiredLangData.seeall_btn);
            } else {
              setTabsValues(initialTabs);
              setSeeAllBtn(initialSeeAll);
            }
          }}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
          {activeTab === 0 ? (
            <>
              {allTestimonials?.length > 0 ? (
                <TopSwiper2 data={allTestimonials} />
              ) : (
                <div className="pr-4">
                  <LoadingSlides />
                  <LoadingSlides />
                  <LoadingSlides />
                  <LoadingSlides />
                </div>
              )}
              <div className="pr-4">
                {allLikedTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={"Testimonial you liked"}
                    data={restructureData(allLikedTestimonials)}
                    // data={ArrayOfImgs7}
                    seeAll={true}
                    dataTitle={false}
                    perViewImages={7}
                    showTitle={true}
                    type={"testimonial"}
                  />
                )}
                {byCategoryHealingTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={"Healing"}
                    data={restructureData(byCategoryHealingTestimonials)}
                    seeAll={true}
                    dataTitle={false}
                    perViewImages={7}
                    showTitle={true}
                    linkTo={"testimonial"}
                  />
                )}
                {byCategoryDPTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={"Divine Provision"}
                    data={restructureData(byCategoryDPTestimonials)}
                    seeAll={true}
                    dataTitle={false}
                    perViewImages={7}
                    showTitle={true}
                    linkTo={"testimonial"}
                  />
                )}
                {byCategorySalvationTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={"Salvation"}
                    data={restructureData(byCategorySalvationTestimonials)}
                    seeAll={true}
                    dataTitle={false}
                    perViewImages={7}
                    showTitle={true}
                    linkTo={"testimonial"}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="m-6 bg-gray-200 bg-opacity-10 h-[70vh] rounded-3xl flex flex-col gap-4 justify-center items-center">
                <img
                  src={Record}
                  onClick={() => setShowRecording((prev) => !prev)}
                  className="cursor-pointer"
                  alt=""
                />

                <p className="text-4xl text-white font-semibold">Start Sharing</p>
                <p className="text-lg text-white text-opacity-50">what the lord has done</p>
              </div>
              {allInReviewTestimonials?.length > 0 && (
                <CommonCarousel
                  title={"Testimonial In Review"}
                  data={restructureData(allInReviewTestimonials)}
                  seeAll={true}
                  dataTitle={false}
                  perViewImages={7}
                  showTitle={true}
                  linkTo={"testimonial"}
                  canDelete={true}
                  handleDelete={(id) => {
                    dispatch(
                      deleteCategoriesTestimoyAction({
                        apiPayloadRequest: {
                          _id: id,
                        },
                        callback: (res) => {
                          if (res?.type === 1) {
                            Toastiy("Testimonial Deleted Successfully!", 1);
                          }
                        },
                      })
                    );
                  }}
                />
              )}
              {allApprovedTestimonials?.length > 0 && (
                <CommonCarousel
                  title={"Testimonial Approved"}
                  data={restructureData(allApprovedTestimonials)}
                  seeAll={true}
                  dataTitle={false}
                  perViewImages={7}
                  showTitle={true}
                  linkTo={"testimonial"}
                />
              )}
            </>
          )}
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default TestimonialComp;
