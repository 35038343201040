import React from 'react'
import Navbar from '../common/Navbar'
import HeaderBar from '../common/HeaderBar'
import Group from '../../assets/icons/Group.svg';
import Group1 from '../../assets/icons/Group-1.svg';
import Group2 from '../../assets/icons/Group-2.svg';
import FooterBar from '../common/FooterBar';
import { useNavigate } from 'react-router-dom';

const GivingTwo = () => {
    const navigate = useNavigate();
    return (
        <div className='flex w-full overflow-hidden'>
            <Navbar active={"Givings"} />
            <div className='ml-[104px] flex flex-col w-full'>
                <HeaderBar
                    popCancle={true}
                    title1={"Givings"}
                    active={"Givings"}
                    back={true}
                    backLink={'/givings'}
                />
                <div className='flex flex-col bg-black h-full overflow-y-auto w-full' >
                    <div className='w-full p-8 grid grid-cols-3 gap-6'>
                        <div className='flex flex-col justify-center w-full h-[70vh] bg-[#EFBC51] rounded-xl p-6'>
                            <div className='h-[60%] flex justify-center items-end'>
                                <img className='h-[20vh]' src={Group} />
                            </div>
                            <div className='h-[40%] flex justify-center items-end'>
                                <button className='justify-self-end w-full p-4 border border-black rounded-lg font-medium' >
                                    Give Now
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center w-full h-[70vh] bg-[#EFBC51] rounded-xl p-6'>
                            <div className='h-[60%] flex justify-center items-end'>
                                <img className='h-[20vh]' src={Group1} />
                            </div>
                            <div className='h-[40%] flex justify-center items-end'>
                                <button className='justify-self-end w-full p-4 border border-black rounded-lg font-medium' >
                                    Set up Recurring
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center w-full h-[70vh] bg-[#EFBC51] rounded-xl p-6'>
                            <div className='h-[60%] flex justify-center items-end'>
                                <img className='h-[20vh]' src={Group2} />
                            </div>
                            <div className='h-[40%] flex justify-center items-end'>
                                <button onClick={() => navigate('/givings/past-list')} className='justify-self-end w-full p-4 border border-black rounded-lg font-medium' >
                                    Your past givings
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className='mx-6 '>
                        <p className='text-lg font-semibold text-white'> Need Help</p>
                        <div className='need-prayer-bg my-5 rounded-2xl flex justify-center items-center'>
                            <button className='bg-black opacity-70 font-semibold text-yellow-500 text-opacity-60 py-5 px-20 rounded-xl uppercase text-2xl'>
                                Need prayer?
                            </button>
                        </div>
                    </div>
                    <FooterBar />
                </div>
            </div>
        </div>
    )
}

export default GivingTwo