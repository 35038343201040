import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAchievementsAction,
  getPickWhereLeftAction,
  readUserFavAction,
  userFavAction,
} from "../../store/Home/HomeAction";
import CommonCarousel from "../home/CommonCarousel";
import AchievementsCarousel from "../home/AchievementsCarousel";
import { testimonialReadAction } from "../../store/Testimonial/TestimonialAction";

const Profile = ({ setEditProfile, profileValues }) => {
  const dispatch = useDispatch();
  const [videoTab, setVideoTab] = useState(0);
  const [testimonialTab, setTestimonialTab] = useState(0);
  const [curriculumTab, setCurriculumTab] = useState(0);
  const [downloadTab, setDownloadTab] = useState(0);
  const [watchedVidoes, setWatchedVideos] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [favVidoes, setFavVideos] = useState([]);
  const [favCurriculums, setFavCurriculums] = useState([]);
  const [favTestimonials, setFavTestimonials] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [moreActivities, setMoreActivities] = useState(5);
  const [moreGivings, setMoreGivings] = useState(5);

  console.log(achievements, "achievements");

  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const arr2 = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    dispatch(
      getPickWhereLeftAction({
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data[0]?.pickuplefts;
            data?.map((item) => {
              if (item?.videoDetails?.length > 0) {
                const currentData = item?.videoDetails[0];
                setWatchedVideos((prev) => [
                  ...prev,
                  {
                    _id: currentData?._id,
                    percentage: item?.percentagewatched,
                    image: currentData?.tumbnailurls?.filter(
                      (i) => i?.type_of_url === "1"
                    )[0]?.url_link,
                    title: currentData?.video_title,
                    subtitle: currentData?.video_title,
                    type: "video",
                  },
                ]);
              }
            });
          }
        },
      })
    );

    dispatch(
      readUserFavAction({
        callback: (res) => {
          const data = res?.data[0]?.favourites;
          data?.map((item) => {
            if (item?.videoDetails?.length > 0) {
              const currentData = item?.videoDetails[0];
              setFavVideos((prev) => [
                ...prev,
                {
                  _id: currentData?._id,
                  percentage: item?.percentagewatched,
                  image: currentData?.tumbnailurls?.filter(
                    (i) => i?.type_of_url === "1"
                  )[0]?.url_link,
                  title: currentData?.video_title,
                  subtitle: currentData?.video_title,
                  type: "video",
                },
              ]);
            }

            if (item?.curriculumDetails?.length > 0) {
              const currentData = item?.curriculumDetails[0];
              setFavCurriculums((prev) => [
                ...prev,
                {
                  _id: currentData?._id,
                  percentage: item?.percentagewatched,
                  image: currentData?.bg_Images?.filter(
                    (i) => i?.type_of_url === "1"
                  )[0]?.url_link,
                  title: currentData?.curriculum_name,
                  subtitle: currentData?.video_title,
                  type: "curriculum",
                },
              ]);
            }

            if (item?.testimonyDetails?.length > 0) {
              const currentData = item?.testimonyDetails[0];
              setFavTestimonials((prev) => [
                ...prev,
                {
                  _id: currentData?._id,
                  percentage: item?.percentagewatched,
                  image: currentData?.tumbnailurls?.filter(
                    (i) => i?.type_of_url === "1"
                  )[0]?.url_link,
                  title: currentData?.video_title,
                  subtitle: currentData?.video_title,
                  type: "testimonial",
                },
              ]);
            }
          });
        },
      })
    );

    dispatch(
      getAchievementsAction({
        callback: (res) => {
          const data = res?.data;
          data.map((item) => {
            setAchievements((prev) => [
              ...prev,
              {
                _id: item?._id,
                title: item?.curriculum_name,
                image: item?.bg_Images?.filter(
                  (item) => item.type_of_url === "1"
                )[0]?.url_link,
              },
            ]);
          });
        },
      })
    );

    dispatch(
      testimonialReadAction({
        callback: (res) => {
          setTestimonials(res?.data);
        },
      })
    );
  }, []);

  const restructureData = (data) => {
    return data?.map((item) => ({
      _id: item._id,
      image: item?.testomonial_rec_tumbnailUrl,
      title: item?.testimonial_name,
      subtitle: item?.subtitle,
      category: item?.categories[0]?._id || "",
    }));
  };

  return (
    <div className="w-full flex flex-col gap-5 pl-4 pr-10 overflow-auto pb-8">
      <p className="text-white text-4xl font-semibold mb-4">My Profile</p>
      <div className="w-full rounded-2xl bg-blackishV2 ">
        <div className="h-[18vh] bg-gradient-to-bl from-indigo-900 to-purple-900 rounded-t-2xl relative">
          <div
            style={{ backgroundImage: `url(${profileValues?.profilePicUrl})` }}
            className="absolute w-28 h-28 bg-black rounded-full left-[2%] -bottom-[50px] z-10 bg-cover"
          ></div>
          <div className="absolute bg-white w-full h-full opacity-10 rounded-t-2xl"></div>
        </div>
        <div className="p-6 flex justify-between">
          <div className="flex flex-col justify-end">
            <p className="text-white text-2xl font-semibold">
              {profileValues?.firstName} {profileValues?.lastName}
            </p>
            <p className="text-white text-lg font-light mb-2">
              {"User ID: " + profileValues?._id}
            </p>
            <p className="text-white opacity-60 font-thin">
              {profileValues?.country}
            </p>
          </div>
          <div className="flex flex-col items-end gap-4 font-semibold">
            <p className="text-white opacity-60 text-sm">
              {"User Sign Up Date: 10/03/2022"}
            </p>
            <p className="text-white opacity-60 text-sm">
              {"Last Logged In: 10/03/2022"}
            </p>
            <div className="flex gap-3 mt-4">
              <button
                className="px-8 py-3 border-2 border-yellowish text-yellowish rounded-xl text-sm"
                onClick={() => setEditProfile((prev) => !prev)}
              >
                Edit Profile
              </button>
              <button className="px-8 py-3 bg-yellowish text-black rounded-xl text-sm">
                Block Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-5 w-full">
        <div className="w-[60%] flex flex-col gap-5">
          {profileValues?.aboutMe && (
            <div className="p-5 w-full rounded-2xl bg-blackishV2 overflow-hidden">
              <p className="text-white text-xl font-semibold mb-4">About</p>
              <p className="text-white opacity-60 font-thin">
                {profileValues?.aboutMe}
              </p>
            </div>
          )}
          <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setVideoTab(0)}
                className={`p-5 px-7 text-white font-semibold  ${
                  videoTab === 0
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Recently Watched Videos
              </button>
              <button
                onClick={() => setVideoTab(1)}
                className={`p-5 px-7 text-white font-semibold  ${
                  videoTab === 1
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Recently Liked Videos
              </button>
            </div>
            <div className="p-4">
              {videoTab === 0 ? (
                <CommonCarousel
                  data={watchedVidoes}
                  perViewImages={4}
                  imageType={"landscape"}
                  showTitle={false}
                  seeAll={false}
                  showProgress={true}
                />
              ) : (
                <CommonCarousel
                  data={favVidoes}
                  perViewImages={4}
                  imageType={"landscape"}
                  showTitle={false}
                  seeAll={false}
                  showProgress={false}
                />
              )}
            </div>
          </div>
          <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setTestimonialTab(0)}
                className={`p-5 px-7 text-white font-semibold  ${
                  testimonialTab === 0
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Testimonials
              </button>
              <button
                onClick={() => setTestimonialTab(1)}
                className={`p-5 px-7 text-white font-semibold  ${
                  testimonialTab === 1
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Liked Testimonials
              </button>
            </div>
            <div className="p-4">
              {testimonialTab === 0 ? (
                <CommonCarousel
                  showTitle={false}
                  data={restructureData(testimonials)}
                  seeAll={false}
                  dataTitle={false}
                  imageType={"landscape"}
                  perViewImages={4}
                  type={"testimonial"}
                />
              ) : (
                <CommonCarousel
                  showTitle={false}
                  data={restructureData(favTestimonials)}
                  seeAll={false}
                  dataTitle={false}
                  imageType={"landscape"}
                  perViewImages={4}
                  type={"testimonial"}
                />
              )}
            </div>
          </div>
          <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setCurriculumTab(0)}
                className={`p-5 px-7 text-white font-semibold  ${
                  curriculumTab === 0
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Liked Curriculumsss
              </button>
              <button
                onClick={() => setCurriculumTab(1)}
                className={`p-5 px-7 text-white font-semibold  ${
                  curriculumTab === 1
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Completed Curriculums
              </button>
            </div>
            <div className="p-4">
              {curriculumTab === 0 ? (
                <CommonCarousel
                  data={favCurriculums}
                  perViewImages={4}
                  imageType={"landscape"}
                  showTitle={false}
                  seeAll={false}
                />
              ) : (
                <CommonCarousel
                  data={achievements}
                  perViewImages={4}
                  imageType={"landscape"}
                  showTitle={false}
                  seeAll={false}
                />
              )}
            </div>
          </div>
          {/* <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setDownloadTab(0)}
                className={`p-5 px-7 text-white font-semibold  ${
                  downloadTab === 0
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Downloaded Videos
              </button>
              <button
                onClick={() => setDownloadTab(1)}
                className={`p-5 px-7 text-white font-semibold  ${
                  downloadTab === 1
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Favourite Videos
              </button>
            </div>
            <div className="h-[25vh]"></div>
          </div> */}
          <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <div className={`p-5 px-7 text-white font-semibold`}>
                Certificates
              </div>
            </div>
            <div className="p-4">
              <AchievementsCarousel
                data={achievements}
                perViewImages={4}
                imageType={"landscape"}
                showTitle={false}
                seeAll={false}
                user={profileValues}
              />
            </div>
          </div>
        </div>
        <div className="w-[40%] flex flex-col gap-5">
          <div className="p-5 px-6 w-full rounded-2xl bg-blackishV2 overflow-hidden flex gap-1 flex-col">
            <p className="text-white text-xl font-semibold mb-4">
              Recent Activity
            </p>
            {arr.slice(0, moreActivities).map((item) => (
              <div className="flex gap-3 bg-blackish rounded-xl p-2">
                <div className="h-14 w-14 bg-black rounded-full"></div>
                <div className="flex flex-col text-base">
                  <p className="text-white font-semibold">Gods Prayer</p>
                  <p className="text-white opacity-70 font-thin">
                    Today with God
                  </p>
                </div>
              </div>
            ))}

            {arr?.length > 5 && (
              <div className="flex justify-end mt-4">
                <button
                  className="text-white opacity-50 text-sm"
                  onClick={() =>
                    setMoreActivities(
                      moreActivities === arr?.length ? 5 : arr?.length
                    )
                  }
                >
                  {moreActivities === arr?.length ? "See less" : "See all"}
                </button>
              </div>
            )}
          </div>
          <div className="p-5 px-6 w-full rounded-2xl bg-blackishV2 overflow-hidden flex gap-1 flex-col">
            <p className="text-white text-xl font-semibold mb-4">
              Recent Activity
            </p>
            {arr2?.slice(0, moreGivings).map((item) => (
              <div className="flex justify-between bg-blackish rounded-xl p-5 py-6">
                <p className="text-white font-semibold">Gods Prayer</p>
                <p className="text-white opacity-70 font-thin">
                  Today with God
                </p>
              </div>
            ))}

            {arr2?.length > 5 && (
              <div className="flex justify-end mt-4">
                <button
                  className="text-white opacity-50 text-sm"
                  onClick={() =>
                    setMoreGivings(
                      moreGivings === arr2?.length ? 5 : arr2?.length
                    )
                  }
                >
                  {moreGivings === arr2?.length ? "See less" : "See all"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
