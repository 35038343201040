import React, { useRef, useState } from "react";
import Navbar from "./Navbar";
import HeaderBar from "./HeaderBar";

import BookOfLuke from "../../assets/images/BookOfLuke.png";
import { ArrayOfImgs, ArrayOfImgs3 } from "../../constants/NavbarConstants";
import SmallCarousel from "../home/SmallCarousel";
import FooterBar from "./FooterBar";
import { useNavigate, useParams } from "react-router-dom";
import Play from "../../assets/icons/play.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { testimonialReadAction } from "../../store/Testimonial/TestimonialAction";
import GotQuestions from "./GotQuestions";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";

const TestimonialInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const videoRef = useRef();
  const { isAuthenticated } = useAuth0();

  const [activeTab, setActiveTab] = useState("Book of Jonh");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const [currentVideoData, setCurrentVideoData] = useState({
    thumbnailUrl: "",
    title: "",
    description: "",
    url: "",
  });

  const [relatedVideos, setRelatedVideos] = useState([]);

  const handleTab1 = () => {
    setActiveTab("Book of Jonh");
  };

  const handleVideosClick = () => {
    navigate("/video-player", {
      state: { url: currentVideoData?.url, title: currentVideoData?.title },
    });
  };

  useEffect(() => {
    dispatch(
      testimonialReadAction({
        apiPayloadRequest: {
          _id: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setRelatedVideos([]);

            const data = res?.data[0];
            const relatedvideosData = data?.relatedvideos;

            setCurrentVideoData((prev) => ({
              ...prev,
              thumbnailUrl: data?.testomonial_rec_tumbnailUrl || "",
              title: data?.testimonial_name || "",
              description: data?.testimonial_description || "",
              url: data?.testomonial_rec_videoUrl || "",
            }));

            // const checkValidRelatedVideos = relatedvideosData?.find(v => v?.videoObjId);

            // if(checkValidRelatedVideos) {
            //     relatedvideosData.map((item) => {
            //         const vidId = item?.videoObjId[0]?._id;
            //         const thumbnail = item?.videoObjId[0]?.tumbnailurls ? item?.videoObjId[0]?.tumbnailurls[0]?.url_link : "";

            //         setRelatedVideos((prev) => ([
            //             ...prev,
            //             {
            //                 _id: vidId,
            //                 url: thumbnail,
            //             }
            //         ]))
            //     })
            // }
          }
        },
      })
    );
  }, [id]);

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          title1={currentVideoData?.title ? currentVideoData?.title : "Book of John"}
          back={true}
          active={activeTab}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-10">
          <div className="relative rounded-3xl overflow-hidden cursor-pointer bg-blackish flex items-center h-[75vh] m-6">
            <div className="w-5/12 relative h-full bg-blackishV2" onClick={() => setIsPlaying(!isPlaying)}>
              <video
                className={`h-full w-full object-contain rounded-xl ${
                  isVideoPlayed ? "block" : "hidden"
                }`}
                ref={videoRef}
                src={currentVideoData?.url}
                onClick={() => videoRef.current.pause()}
              />
              <div className={`h-full w-full ${isVideoPlayed ? "hidden" : "block"}`}>
                {currentVideoData?.thumbnailUrl ? (
                  <img
                    src={currentVideoData?.thumbnailUrl}
                    className="object-cover object-top rounded-md h-full !w-full bg-gray-600"
                    alt="testimonial"
                  />
                ) : (
                  <div className="rounded-md h-full !w-full bg-gray-600"></div>
                )}
              </div>

              {!isPlaying && (
                <>
                  <div
                    className={`z-5 absolute top-0 left-0 h-full w-full bg-black bg-opacity-20 ${
                      isVideoPlayed ? "hidden" : "block"
                    }`}
                  ></div>
                  <img
                    src={Play}
                    className="z-11 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md !w-[25%] cursor-pointer"
                    alt="playBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      if(!isAuthenticated){
                        dispatch(setLoginHardBlocker(true));
                        return; 
                      }
                      setIsPlaying(true);
                      setIsVideoPlayed(true);
                      videoRef.current.play();
                    }}
                  />
                </>
              )}
            </div>
            <div className="px-10 w-7/12">
              <p className="text-white text-2xl text-left px-5 font-bold">
                {currentVideoData?.title}
              </p>
              <p className="text-white text-opacity-60 text-sm text-left px-5">
                {currentVideoData?.description}
              </p>
            </div>
          </div>
          {relatedVideos?.length > 0 && (
            <div className="px-6">
              <SmallCarousel
                title={"Related Videos"}
                data={relatedVideos}
                seeAll={true}
                dataTitle={false}
                perViewImages={7}
                aspectRatio={"10/16"}
                height={"32"}
              />
            </div>
          )}
          <GotQuestions />
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default TestimonialInfo;
