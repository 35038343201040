import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileAction,
  saveUserProfileAction,
  updateUserProfileAction,
} from "../../../store/Home/HomeAction";
import Toastiy from "../../common/Toastiy";
import { useNavigate } from "react-router-dom";

export const ProfileHooks = () => {
  const laguage = useSelector((state) => state.languageReducer.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [editProfile, setEditProfile] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [profileValues, setProfileValues] = useState({
    _id: "",
    profilePicUrl: "",
    dob: "",
    gender: "male",
    firstName: "",
    lastName: "",
    aboutMe: "",
    mobile: "",
    email: "",
    active: 1,
    language_prefference: laguage.languagePreferred,
    country: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setProfileValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    dispatch(
      getProfileAction({
        callback: getProfileSuccess,
      })
    );
  };

  const getProfileSuccess = (res) => {
    if (res?.type === 1 && res?.data?.length !== 0) {
      setProfileValues((prevState) => ({
        ...prevState,
        firstName: res?.data[0]?.firstName || "",
        lastName: res?.data[0]?.lastName || "",
        aboutMe: res?.data[0]?.aboutMe || "",
        profilePicUrl: res?.data[0]?.profilePicUrl || "",
        email: res?.data[0]?.email,
        mobile: res?.data[0]?.mobile || "",
        _id: res?.data[0]?._id || "",
      }));
    }
    // else {
    //   navigate("/login");
    // }
  };

  const handleUpdateProfile = () => {
    // if (profileValues?._id !== "") {
    dispatch(
      updateUserProfileAction({
        apiPayloadRequest: profileValues,
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Profile Saved Successfully!", res?.type);
            setEditProfile((prevState) => !prevState);
          } else {
            Toastiy("Something Went Wrong!", res?.type);
            setEditProfile((prevState) => !prevState);
          }
        },
      })
    );
    // } else {
    //   dispatch(
    //     saveUserProfileAction({
    //       apiPayloadRequest: profileValues,
    //       callback: (res) => {
    //         if (res?.type === 1) {
    //           Toastiy("Proile Updated Successfully!", res?.type);
    //         } else {
    //           Toastiy("Something Went Wrong!", res?.type);
    //         }
    //       },
    //     })
    //   );
    // }
  };

  return {
    value,
    editProfile,
    setEditProfile,
    profileValues,
    handleChange,
    onChangeSearchBar,
    imageUrl,
    setImageUrl,
    handleUpdateProfile,
    setProfileValues,
  };
};
