import * as actionTypes from "../ActionTypes";

export const getCategoriesAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_READ_REQUEST,
    payload: payload,
  };
};

export const saveCategoriesTestimoyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_SAVE_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesTestimoyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_UPDATE_REQUEST,
    payload: payload,
  };
};

export const deleteCategoriesTestimoyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_DELETE_REQUEST,
    payload: payload,
  };
};
