import React from 'react'
import HeaderBar from '../common/HeaderBar'
import Navbar from '../common/Navbar'
import FooterBar from '../common/FooterBar'

const GivingPastList = () => {
    return (
        <div className='flex w-full overflow-hidden'>
            <Navbar active={"Givings"} />
            <div className='ml-[104px] flex flex-col w-full'>
                <HeaderBar
                    popCancle={true}
                    title1={"Your Past Givings"}
                    active={"Your Past Givings"}
                    back={true}
                    backLink={'/givings/comp'}
                />
                <div className='flex flex-col items-center bg-black h-full overflow-y-auto w-full' >
                    <div className='w-[90%] pt-6'>
                        <div className='w-full py-4 grid grid-cols-8'>
                            <p className='text-xs text-white text-opacity-50 font-medium col-span-2'>Transaction ID</p>
                            <p className='text-xs text-white text-opacity-50 font-medium col-span-2'>Date & Time</p>
                            <p className='text-xs text-white text-opacity-50 font-medium'>Country</p>
                            <p className='text-xs text-white text-opacity-50 font-medium col-span-2'>Amount</p>
                            <p className='text-xs text-white text-opacity-50 font-medium'>Made of Payment</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                        <div className='w-full py-5 px-3 bg-[#1F1F1F]  grid grid-cols-8 rounded-lg mt-1'>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>#TQNUJ89001</p>
                            <p className='text-[14px] text-white text-opacity-50  col-span-2'>21/06/2023 | 09:29 AM</p>
                            <p className='text-[14px] text-white text-opacity-50'>Australis</p>
                            <p className='text-[14px] text-white text-opacity-50 col-span-2'>20$</p>
                            <p className='text-[14px] text-white text-opacity-50 pl-2'>UPI</p>
                        </div>
                    </div>
                    <FooterBar />
                </div>
            </div>
        </div>
    )
}

export default GivingPastList