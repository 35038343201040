import React, { useEffect, useState } from "react";
import HeaderBar from "../../components/common/HeaderBar";
import Navbar from "../../components/common/Navbar";
import SmallCarousel from "../../components/home/SmallCarousel";
import PromiseCarousel from "../../components/home/PromiseCarousel";
import FooterBar from "../../components/common/FooterBar";
import {
  ArrayOfImgs2,
  ArrayOfImgs5,
  ArrayOfImgs6,
  ArrayOfImgs7,
} from "../../constants/NavbarConstants";
import CommonCarousel from "../../components/home/CommonCarousel";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { readUserFavAction, setLoginHardBlocker } from "../../store/Home/HomeAction";
import LoadingSlides from "../../components/common/LoadingSlides";

const MyFavourites = () => {
  const dispatch = useDispatch();
  const checkToken = localStorage.getItem("token");
  const initialTabs = ["My Favourites"];
  const initialSeeAll = "See All";

  const [tabValues, setTabsValues] = useState(initialTabs);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [favVideos, setFavVideos] = useState([]);
  const [favCurriculum, setFavCurriculum] = useState([]);
  const [favCategory, setFavCategory] = useState([]);

  console.log(favVideos, favCurriculum, favCategory, "favCategory");

  const restructureVideoData = (data) => {
    return {
      _id: data?._id,
      image: data?.tumbnailurls[1]?.url_link,
      title: data?.video_title,
    };
  };

  const restructureCurriculumData = (data) => {
    return {
      _id: data?._id,
      image: data?.bg_Images[1]?.url_link,
      title: data?.curriculum_name,
    };
  };

  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    setFavVideos([]);
    setFavCategory([]);
    setFavCurriculum([]);
    if (checkToken) {
      dispatch(
        readUserFavAction({
          callback: (res) => {
            const data = res?.data[0]?.favourites;
            data.map((item) => {
              if (item?.videoDetails?.length > 0) {
                setFavVideos((prev) => [
                  ...prev,
                  restructureVideoData(item?.videoDetails[0]),
                ]);
              }
              if (item?.categoryDetails?.length > 0) {
                setFavCategory((prev) => [...prev, item?.categoryDetails[0]]);
              }
              if (item?.curriculumDetails?.length > 0) {
                setFavCurriculum((prev) => [
                  ...prev,
                  restructureCurriculumData(item?.curriculumDetails[0]),
                ]);
              }
            });
          },
        })
      );
    }
  }, [currentCode, checkToken]);

  useEffect(()=> {
    if(!isAuthenticated) {
        dispatch(setLoginHardBlocker(true));
    }      
}, [isAuthenticated]);

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"My Favourites"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          enableGoToHome={true}
          tabValues={tabValues}
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={(code) => {
            setCurrectCode(code);
          }}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.my_favourites) {
              const requiredLangData = titles?.my_favourites;
              setTabsValues([requiredLangData.welcome_lbl || initialTabs]);

              setSeeAllBtn(requiredLangData.see_all_btn || initialSeeAll);
            } else {
              setTabsValues(initialTabs);
              setSeeAllBtn(initialSeeAll);
            }
          }}
        />
        <div className="flex flex-col pr-[1%] bg-black h-full overflow-y-auto w-full">
          {favVideos.length > 0 ? (
            <CommonCarousel
              title={"Vidoes"}
              linkTo={""}
              data={favVideos}
              perViewImages={7}
            />
          ) : (
            <LoadingSlides />
          )}
          {favCategory.length > 0 ? (
            <CommonCarousel
              title={"Categories"}
              linkTo={""}
              data={favCategory}
              perViewImages={7}
            />
          ) : (
            <LoadingSlides />
          )}
          {favCurriculum.length > 0 ? (
            <CommonCarousel
              title={"Curriculums"}
              linkTo={""}
              data={favCurriculum}
              perViewImages={5}
              imageType={"landscape"}
              showLikes={true}
            />
          ) : (
            <LoadingSlides />
          )}
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default MyFavourites;
