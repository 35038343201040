// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Play from "../../assets/icons/play.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, EffectCoverflow, Navigation } from "swiper/modules";

import Img1 from "../../assets/images/backgroundImg.png";
import Img2 from "../../assets/images/img1.png";
import { useNavigate } from "react-router-dom";

const TopSwiper2 = (props) => {
  const data = props?.data;
  const navigate = useNavigate();
  return (
    <div className="p-6">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 1200,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        pagination={true}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper flex justify-center"
      >
        {data ? (
          data?.map((row, index) => (
            <SwiperSlide key={index}>
              <div className={`p-2 flex justify-center`}>
                <div className="bg-black flex items-center w-[65%] p-4 rounded-2xl border border-white border-opacity-30">
                  <div className="w-5/12 relative">
                    <img
                      src={row?.testomonial_rec_tumbnailUrl}
                      className="object-cover object-top rounded-md h-full !w-full bg-gray-600"
                      alt="testimonial"
                    />
                    <div className="z-5 absolute top-0 left-0 h-full w-full bg-black bg-opacity-20"></div>
                    <img
                      src={Play}
                      className="z-11 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md !w-[25%] cursor-pointer"
                      alt="playBtn"
                      onClick={() =>
                        navigate(`/testimonialInfo/${row?._id}`, {
                          state: { _id: row?._id, fromLink: "/home" },
                        })
                      }
                    />
                  </div>
                  <div className="px-10 w-7/12">
                    <p className="text-white text-2xl text-left px-5 font-bold">
                      {row?.testimonial_name}
                    </p>
                    <p className="text-white text-opacity-60 text-sm text-left px-5">
                      {row?.testimonial_description}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img2} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img1} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img2} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img1} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img2} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img1} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img2} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 flex justify-center">
                <img src={Img1} />
                <div className="absolute bottom-10 w-full px-[10%]">
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                  <p className="text-white text-lg text-left px-5">
                    How to Use Lorem Ipsum. For plaintext Lorem Ipsum
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default TopSwiper2;
