import React, { useState, useRef, useEffect, useCallback } from "react";
import { MdOutlineCancel } from "react-icons/md";
import VideoPlayer from "./VideoPlayer";
import { useLocation, useNavigate } from "react-router-dom";
import screenfull from "screenfull";
import PlayControls from "./PlayControls";
import { useDispatch } from "react-redux";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";
import LoginHardBlocker from "../common/LoginHardBlocker";
import { getDataFromIndexedDB, processVideoDownload } from "../../utils/VideoDownloadAndSave";

const VideoPlayerContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth0();

  const initialPlayerValues = {
    playing: true,
    muted: false,
    volume: 0.2,
    played: 0,
    fullscreen: false,
  };

  const [playerStates, setPlayerStates] = useState(initialPlayerValues);
  const [videoUrl, setVideoUrl] = useState("");
  const [isMouseMoving, setIsMouseMoving] = useState(true);

  // useEffect(() => {
  //     const handleMouseMove = () => {
  //         setIsMouseMoving(true);
  //         const timer = setTimeout(() => {
  //             setIsMouseMoving(false);
  //         }, 5000);

  //         return () => {
  //             clearTimeout(timer);
  //         };
  //     };

  //     document.addEventListener('mousemove', handleMouseMove);

  //     return () => {
  //         document.removeEventListener('mousemove', handleMouseMove);
  //     };
  // }, []);

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);

  const { playing, muted, volume, played, fullscreen } = playerStates;

  const handlePlayPause = () => {
    setPlayerStates({ ...playerStates, playing: !playerStates.playing });
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleVolumeChange = (event) => {
    setPlayerStates({
      ...playerStates,
      volume: parseFloat(event.target.value / 100),
      muted: event.target.value === 0 ? true : false,
    });
  };

  const handleVolumeSeekDown = (event) => {
    setPlayerStates({
      ...playerStates,
      volume: parseFloat(event.target.value / 100),
      muted: event.target.value === 0 ? true : false,
    });
  };

  const handleToggleFullScreen = () => {
    setPlayerStates({
      ...playerStates,
      fullscreen: !playerStates.fullscreen,
    });
    screenfull.toggle(playerContainerRef.current);
  };

  const handleProgress = (changeState) => {
    // console.log(changeState);
    setPlayerStates({
      ...playerStates,
      ...changeState,
    });
  };

  const handleSeekChange = (event) => {
    setPlayerStates({
      ...playerStates,
      played: parseFloat(event.target.value / 100),
    });
  };

  const onVideoDownload = async () => {
    const record = {
      _id: location?.state?._id,
      url: location?.state?.url,
      title: location?.state?.title,
      image: location?.state?.thumbnailUrl,
      category: location?.state?.category,
    };
    await processVideoDownload(record);
  };

  useEffect(() => {
    // console.log("location?.state", location?.state);
    // if (location?.state?.download && location?.state?._id) {
    //   getDataFromIndexedDB(location?.state?._id, location?.state?.category).then((result) => {
    //     const blob = new Blob([result?.data], { type: "video/mp4" });
    //     const newVideoUrl = URL.createObjectURL(blob);
    //     console.log("videoData", result);
    //     console.log("newVideoUrl", newVideoUrl);
    //     setVideoUrl(newVideoUrl);
    //   });
    // } else {
      setVideoUrl(location?.state?.url);
    // }
  }, []);

  console.log("videoUrl", videoUrl);

  // useEffect(()=> {
  //     if(!isAuthenticated) {
  //         handlePlayPause();
  //         dispatch(setLoginHardBlocker(true));
  //     }
  // }, [isAuthenticated]);

  return (
    <div
      className={`relative flex flex-col w-full bg-black ${!isMouseMoving ? "cursor-none" : ""}`}
    >
      <button onClick={() => navigate(-1)} className="absolute top-5 right-5 z-50">
        <MdOutlineCancel color="white" size={"2em"} />
      </button>
      <div className="w-full flex justify-center">
        {videoUrl && (
          <VideoPlayer
            url={videoUrl}
            onVideoDownload={onVideoDownload}
            playerContainerRef={playerContainerRef}
            playerRef={playerRef}
            muted={muted}
            playing={playing}
            volume={volume}
            handleProgress={handleProgress}
            title={location?.state?.title || ""}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onFastForward={handleFastForward}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            played={played}
            onToggleFullScreen={handleToggleFullScreen}
            onSeek={handleSeekChange}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            fullscreen={fullscreen}
            isMouseMoving={isMouseMoving}
            showDownload={isAuthenticated && !location?.state?.download}
          />
        )}
      </div>
      {!fullscreen && isMouseMoving && (
        <PlayControls
          onVideoDownload={onVideoDownload}
          showDownload={isAuthenticated && !location?.state?.download}
          title={location?.state?.title || ""}
          onPlayPause={handlePlayPause}
          onRewind={handleRewind}
          onFastForward={handleFastForward}
          onVolumeChange={handleVolumeChange}
          onVolumeSeekDown={handleVolumeSeekDown}
          volume={volume}
          muted={muted}
          playing={playing}
          played={played}
          onToggleFullScreen={handleToggleFullScreen}
          onSeek={handleSeekChange}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
        />
      )}
      <LoginHardBlocker enableGoToHome={true} />
    </div>
  );
};

export default VideoPlayerContainer;

const format = (sec) => {
  if (isNaN(sec)) {
    return "00:00";
  }

  const date = new Date(sec * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }

  return `${mm}:${ss}`;
};
