import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import TestCarousel from "../../components/home/TestCarousel";
import PromiseCarousel from "../../components/home/PromiseCarousel";
import SmallCarousel from "../../components/home/SmallCarousel";

import AwardImg from "../../assets/images/AwardImg.png";
import FooterBar from "../../components/common/FooterBar";
import {
  ArrayOfImgs,
  ArrayOfImgs2,
  ArrayOfImgs4,
} from "../../constants/NavbarConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import CommonCarousel from "../../components/home/CommonCarousel";
import Certificate from "../../components/curriculum/Certificate";
import HomeCurriculumCarousel from "../../components/home/HomeCurriculumCarousel";
import LoadingSlides from "../../components/common/LoadingSlides";
import {
  getAchievementsAction,
  getProfileAction,
  setLoginHardBlocker,
} from "../../store/Home/HomeAction";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import award from "../../assets/images/certificate.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const Curriculum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001,
      padding: 0, // Adjust this value as needed
    },
  };
  const initialTabs = ["Learn", "Your Achievements"];
  const initialSeeAll = "See All";
  const checkToken = localStorage.getItem("token");
  const initialMoreVideos = "More Videos";
  const pdfContentRef = useRef(null);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [moreVideosbtn, setMoreVideosbtn] = useState(initialMoreVideos);
  const [currentCode, setCurrectCode] = useState("");
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [curriculumData, setCurriculumData] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [user, setUser] = useState();
  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };
  const generatePDF = () => {
    const input = pdfContentRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  useEffect(() => {
    dispatch(
      curriculumReadAction({
        callback: (res) => {
          if (res?.type === 1) {
            setCurriculumData(res?.data);
          }
        },
      })
    );
    dispatch(
      getAchievementsAction({
        callback: (res) => {
          const data = res?.data;
          data.map((item) => {
            setAchievements((prev) => [
              ...prev,
              {
                _id: item?._id,
                title: item?.curriculum_name,
                image: item?.bg_Images?.filter(
                  (item) => item.type_of_url === "1"
                )[0]?.url_link,
              },
            ]);
          });
        },
      })
    );
    dispatch(
      getProfileAction({
        callback: (res) => {
          setUser(res?.data[0]);
        },
      })
    );
  }, [currentCode]);

  return (
    <>
      <div className="flex w-full overflow-hidden">
        <Navbar active={"Curriculum"} />
        <div className="ml-[104px] flex flex-col w-full min-h-screen">
          <HeaderBar
            popCancle={true}
            tabValues={tabValues}
            active={activeTab}
            handleTabChange={handleTabChange}
            getLanguagePreferred={(code) => {
              setCurrectCode(code);
            }}
            getCurrentLanguageTitles={(titles) => {
              if (titles?.curricullum_meta) {
                const requiredLangData = titles?.curricullum_meta;
                setTabsValues([
                  requiredLangData.learn_lbl,
                  requiredLangData.yourachievement_lbl,
                ]);
                setMoreVideosbtn(
                  requiredLangData.morevideos_btn || initialMoreVideos
                );
                setSeeAllBtn(requiredLangData.seeall_btn);
              } else {
                setTabsValues(initialTabs);
                setSeeAllBtn(initialSeeAll);
                setMoreVideosbtn(initialMoreVideos);
              }
            }}
          />
          <div className="flex flex-col pr-[1%] bg-black flex-1 overflow-y-auto w-full justify-between">
            {activeTab === 0 ? (
              <>
                {curriculumData?.length > 0 && (
                  <TestCarousel
                    data={curriculumData?.map((curriculum) => ({
                      _id: curriculum?._id,
                      title: curriculum?.curriculum_name,
                      subtitle: curriculum?.curriculum_description,
                      image: curriculum?.bg_Images?.filter(
                        (i) => i?.type_of_url === "1"
                      )[0]?.url_link,
                    }))}
                    title={"Learn the word with Us"}
                  />
                )}
                {curriculumData?.length > 0 ? (
                  curriculumData?.map((curriculum) => (
                    <HomeCurriculumCarousel
                      id={curriculum?._id}
                      title={curriculum?.curriculum_name}
                      description={curriculum?.curriculum_description}
                      bgImage={
                        curriculum?.bg_Images?.filter(
                          (i) => i?.type_of_url === "1"
                        )[0]?.url_link
                      }
                      data={curriculum?.lessons?.map((item) => ({
                        _id: curriculum?._id,
                        title: item?.lesson_title,
                        subtitle: item?.lesson_title,
                        image: item?.lesson_tumbnail_urls?.filter(
                          (i) => i?.type_of_url === "1"
                        )[0]?.url_link,
                      }))}
                    />
                  ))
                ) : (
                  <>
                    <LoadingSlides />
                    <LoadingSlides />
                    <LoadingSlides />
                  </>
                )}
              </>
            ) : (
              <>
                {/* <div className="text-yellowish py-28 text-center text-xl font-medium">No Data</div> */}
                <div className="grid grid-cols-3 p-5 gap-6">
                  {achievements.map((item) => (
                    <button onClick={() => setCurrentItem(item)}>
                      <Certificate
                        data={item}
                        setCurrentItem={setCurrentItem}
                        setShowModal={setShowModal}
                      />
                    </button>
                  ))}
                </div>
              </>
            )}
            <FooterBar />
          </div>
        </div>
        <ReactModal
          style={customStyles}
          isOpen={showModal}
          className={"overflow-hidden"}
          onRequestClose={() => setShowModal(false)}
        >
          <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
            <button
              className="absolute top-5 right-5"
              onClick={() => setShowModal(false)}
            >
              <MdCancel color="#EFBC51" size={30} />
            </button>
            <div className="aspect-video rounded-2xl bg-black h-1/2 flex justify-center items-center flex-col">
              <div
                style={{ backgroundImage: `url(${award})` }}
                className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
              ></div>
              <p className="text-xl text-yellowish">Congratulations</p>
              <p className="text-2xl text-white">
                {currentItem?.title?.length < 20
                  ? currentItem?.title
                  : currentItem?.title?.substring(0, 20) + ".."}
              </p>
            </div>
            <button
              onClick={generatePDF}
              className="bg-yellowish px-6 py-4 text-xl font-semibold rounded-xl"
            >
              Download Certificate
            </button>
          </div>
          <div
            className="flex flex-col justify-start items-center "
            ref={pdfContentRef}
            style={{
              width: "595.28px",
              height: "842px",
              color: "black",
              background: "white",
            }}
          >
            <p className="font-bold text-2xl mb-4">
              Hello {user?.firstName} {user?.lastName}
            </p>
            <p>
              Congratulations on successfully completion of {currentItem?.title}{" "}
            </p>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Curriculum;
