import React, { useState } from 'react';
import { navIconArray, logo } from '../../constants/NavbarConstants';
import { NavLink, Link } from 'react-router-dom';

const Navbar = ({ active }) => {

  const [showName, setShowName] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);


  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };


  const onMouseOver = () => {
    setShowName(true);
  }

  const onMouseOut = () => {
    setShowName(false);
  }

  return (
    <div className='p-10 fixed bg-black h-[100vh] flex flex-col justify-center z-50'>
      <img className="absolute top-4 left-5 cursor-pointer w-[60px]" src={logo} alt="" />
      <ul
        className='navbar-main relative flex flex-col h-full justify-center items-center'
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {navIconArray.map((item, i) => (

          <li
            className={`cursor-pointer py-5 z-10`}
          >
            <div className='relative z-10'>
              <img
                className="nav-icons cursor-pointer"
                src={active === item.title ? item.hover : item.icon}
                alt=""
              />
            </div>
          </li>

        ))}
        {
          showName &&
          <li className='nav-hover-bg absolute h-[100vh] w-[300px] left-0 flex flex-col justify-center'>
            {navIconArray.map((item, i) => (
              <NavLink
                className='nav-container flex'
                key={i}
                to={item.route}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  className="nav-icons cursor-pointer"
                  src={hoveredItem === i ? item.hover : item.icon}
                  alt=""
                />
                <p key={i} className='nav-titles top-0 left-10 py-5 pl-4 z-50 cursor-pointer font-semibold text-white opacity-50 '>{item.title}</p>
              </NavLink>
            ))}
          </li>
        }

      </ul>

    </div >
  )
}

export default Navbar