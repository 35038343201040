import React from "react";
import FooterBar from "./FooterBar";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

const MobileAppDownload = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-between items-center bg-black text-white md:hidden">
      <div className="p-[5%]"></div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-2xl font-black text-white">Donwload the app on!</p>
        <div className="bg-black p-4 px-10 flex gap-5 items-center rounded-full mt-[25%] shadow-yellow-500 shadow-inner cursor-pointer">
          <FaGooglePlay size={30} />
          <div className="flex flex-col justify-center">
            <p className="text-white font-semibold text-lg">Google Play</p>
          </div>
        </div>
        <div className="bg-black p-4 px-10 flex gap-9 items-center rounded-full mt-[5%] shadow-yellow-500 shadow-inner cursor-pointer">
          <FaApple size={32} />
          <div className="flex flex-col justify-center">
            <p className="text-white font-semibold text-lg">App Store</p>
          </div>
        </div>
      </div>
      <FooterBar />
    </div>
  );
};

export default MobileAppDownload;
