import React from "react";
import TopVid from "../../assets/images/TopVid.png";
import Play from "../../assets/icons/play.svg";
import { useNavigate } from "react-router-dom";

const TopVideo = ({ background, url }) => {
  const navigate = useNavigate();
  return (
    <div className="m-6">
      <div
        style={{ backgroundImage: `url(${background})` }}
        className="bg-no-repeat bg-cover h-[70vh] w-full relative rounded-3xl flex justify-center items-center"
      >
        <img
          className="h-24 cursor-pointer"
          src={Play}
          onClick={() => {
            navigate("/video-player/", { state: { url: url } });
          }}
        />
        {/* <div className='p-12 flex gap-4 flex-col absolute left-0 bottom-0 max-w-[600px]'>
                    <p className='text-white text-4xl font-semibold'>Today with God</p>
                    <p className='text-gray-200 text-sm text-left'>How to Use Lorem Ipsum. For plaintext Lorem Ipsum, type lorem then press the Ctrl-Shift-L keyboard shortcut. The default keyboard shortcut is the same for all supported platforms. You can also add options to the lorem command with an underscore character followed by the option name.</p>
                </div> */}
      </div>
    </div>
  );
};

export default TopVideo;
