import React, { useState } from "react";
import { BsStar, BsStarFill, BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { CircularCheckbox } from "../InputComps/CircularCheckbox";
import { useDispatch } from "react-redux";
import { saveSupportRequestAction } from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";

const HomeSupportUs = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [likedMost, setLikedMost] = useState("");
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "likedMost") setLikedMost(value);
    if (name === "feedback") setFeedback(value);
  };

  const clearField = () => {
    setRating(0);
    setLikedMost("");
    setFeedback("");
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    clearField();
  };

  const handleSubmit = () => {
    //save form data
    const data = { feedback: feedback, likedmost: likedMost, rating: rating };

    dispatch(
      saveSupportRequestAction({
        apiPayloadRequest: data,
        callback: (res) => {
          Toastiy("Support Request sent!", 1);
          closeModal();
        },
      })
    );
  };

  return (
    <>
      <div className="z-30">
        <p className=" text-lg font-semibold text-white">Support Us</p>
        <div className="support-us-bg my-5 rounded-2xl flex justify-center items-center">
          <button
            className="bg-black opacity-70 font-semibold text-yellow-500 text-opacity-60 py-5 px-20 rounded-xl uppercase text-2xl"
            onClick={() => {
              openModal();
            }}
          >
            Support
          </button>
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001, // Adjust this value as needed
          },
        }}
        className={
          "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
        }
        isOpen={showModal}
        onRequestClose={() => {
          closeModal();
        }}
      >
        <button
          className="absolute left-4 top-4"
          onClick={() => {
            closeModal();
          }}
        >
          <BsX size={40} color="white" />
        </button>
        <div className="h-[90vh] w-5/12 bg-black rounded-xl flex justify-center items-center cursor-pointer relative overflow-y-auto">
          <div className="absolute bottom-0 py-10 px-12 w-full h-full object-cover rounded-xl flex flex-col">
            <div className="mb-6">
              <label className="text-yellowish text-xl font-semibold mb-3">
                How do you rate the overall App?
              </label>
              <div className="flex gap-5 mt-3">
                {[...Array(5)].map((e, i) =>
                  rating >= i + 1 ? (
                    <BsStarFill
                      className="text-yellow-500 text-2xl"
                      onClick={() => {
                        setRating(i + 1);
                      }}
                    />
                  ) : (
                    <BsStar
                      className="text-yellow-500 text-2xl"
                      onClick={() => {
                        setRating(i + 1);
                      }}
                    />
                  )
                )}
              </div>
            </div>
            <div className="mb-6">
              <label className="text-yellowish text-xl font-semibold mb-3">
                What did you like the most?
              </label>
              <div className="flex flex-col gap-3 mt-3 mb-8">
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Videos"
                    name="likedMost"
                    checked={likedMost === "Videos"}
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">Videos</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Curriculum"
                    name="likedMost"
                    checked={likedMost === "Curriculum"}
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">Curriculum</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Testimonies"
                    name="likedMost"
                    checked={likedMost === "Testimonies"}
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">Testimonies</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Today with God"
                    name="likedMost"
                    checked={likedMost === "Today with God"}
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">Today with God</p>
                </div>
              </div>
              <div className="mb-6">
                <label className="text-yellowish text-xl font-semibold mb-6">
                  What would you like to explore next?
                </label>
                <textarea
                  placeholder="Type here.."
                  rows="3"
                  name="feedback"
                  className="border border-yellowish p-4 bg-transparent mt-4 rounded-xl text-white w-full focus-within:outline-none"
                  onChange={handleInputChange}
                />
              </div>
              <button
                className="bg-yellowish text-yellow-800 font-medium rounded-full flex justify-center w-5/12 py-3 mt-3"
                onClick={handleSubmit}
              >
                Send feedback
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default HomeSupportUs;
