import React, { useState } from "react";
import Play from "../../assets/icons/play.svg";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { BsHeart, BsPlayCircleFill } from "react-icons/bs";
import TestimonialVideoModel from "./TestimonialVideoModel";

const HomeTestimony = ({ backgroundImage, _id, testimonials }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const totalCount = testimonials?.length;
  const numberOfLists = Math.ceil(totalCount / 5);
  const [currentTestmonial, setCurrentTestimonial] = useState();

  const array = [
    backgroundImage,
    backgroundImage,
    backgroundImage,
    backgroundImage,
  ];

  const handleNavigate = (data) => {
    setShowModal((prev) => !prev);
    setCurrentTestimonial(data);
  };

  console.log('testimonials', testimonials)

  return (
    <div className="p-6 flex flex-col justify-center items-center w-full">
      <p className="text-xl text-white font-semibold w-full mb-4 mt-4">
        Testimony Videos
      </p>
      <Carousel
        className="p-1 w-full"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {testimonials?.slice(0, numberOfLists)?.map((_, index) => (
          <div className="flex w-full gap-[1%]">
            {testimonials?.slice(index * 4, (index + 1) * 4).map((item, i) => (
              <div
                key={i}
                onMouseOver={() => setActiveIndex(i)}
                style={{
                  backgroundImage: `url(${item?.image})`,
                }}
                className={`${
                  i === activeIndex
                    ? `aspect-[16/9] w-2/5`
                    : `aspect-[9/16] w-1/5`
                } bg-zinc-700 rounded-lg overflow-hidden bg-cover bg-top bg-no-repeat transition-all duration-300 hover:transition-all hover:duration-300`}
              >
                {i === activeIndex && (
                  <div className="flex flex-col justify-between items-center w-full h-full">
                    <div className="flex justify-between w-full h-[35%] p-[5%] bg-gradient-to-b from-black from-[2%]">
                      <div className="flex w-full gap-2 items-center h-[25%]">
                        <div
                          style={{
                            backgroundImage: `url(${item?.profilePic})`,
                          }}
                          className="h-full rounded-full aspect-square bg-yellowish bg-cover bg-no-repeat bg-center"
                        ></div>
                        <p className=" text-white w-full text-start">
                          {item?.userName ? item?.userName : "No User Name"}
                        </p>
                      </div>
                      <BsHeart
                        size={30}
                        color="white"
                        className="cursor-pointer"
                      />
                    </div>
                    <BsPlayCircleFill
                      onClick={() => handleNavigate(item)}
                      color="#EFBC51"
                      className="w-[15%] h-auto cursor-pointer"
                    />
                    <div className="flex flex-col justify-end items-start w-full h-[35%] p-[5%] bg-gradient-to-t from-black from-[2%]">
                      <p className="text-white text-xl font-bold mb-2">
                        {" "}
                        {item?.title?.length <= 15
                          ? item?.title
                          : item?.title?.substring(0, 15) + ".."}
                      </p>
                      <p className="text-white text-xs text-opacity-60 text-start">
                        {item?.subtitle?.length <= 200
                          ? item?.subtitle
                          : item?.subtitle?.substring(0, 200) + ".."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
      <TestimonialVideoModel
        isOpen={showModal}
        onRequestClose={() => setShowModal((prev) => !prev)}
        data={currentTestmonial}
      />
    </div>
  );
};

export default HomeTestimony;
