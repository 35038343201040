import React, { useEffect, useRef, useState } from "react";
import Search from "../../assets/icons/Search.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SearchBar = ({ placeholder, name }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchActive, setSearchActive] = useState(false);

  const containerRef = useRef();

  const {
    categories: globalCategories,
    testimonials: globalTestimonials,
    videos: globalVideos,
    curriculum: globalCurriculum,
  } = useSelector((state) => state.common);

  const searchInStore = (text) => {
    const categoryResults = globalCategories?.filter(
      (item) =>
        text === "" || (item?.title && item?.title?.toLowerCase()?.includes(text?.toLowerCase()))
    );
    const testimonialResults = globalTestimonials.filter(
      (item) =>
        text === "" || (item?.title && item?.title?.toLowerCase()?.includes(text?.toLowerCase()))
    );
    const videoResults = globalVideos?.filter(
      (item) =>
        text === "" || (item?.title && item?.title?.toLowerCase()?.includes(text?.toLowerCase()))
    );
    const curriculumResults = globalCurriculum?.filter(
      (item) =>
        text === "" || (item?.title && item?.title?.toLowerCase()?.includes(text?.toLowerCase()))
    );

    let result = null;
    if (categoryResults || testimonialResults || videoResults || curriculumResults) {
      result = {
        categories: categoryResults,
        testimonials: testimonialResults,
        videos: videoResults,
        curriculum: curriculumResults,
      };
    }
    setSearchResult(result);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
    searchInStore(e.target.value);
  };

  useEffect(() => {
    const eventHandler = (e) => {
      if (containerRef.current && !containerRef.current?.contains(e.target)) {
        setSearchActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  useEffect(() => {
    searchInStore("");
  }, [globalCategories, globalTestimonials, globalVideos, globalCurriculum]);

  return (
    <div className="px-[10%] w-full" ref={containerRef}>
      <div
        className={`relative flex gap-3 px-4 py-[13px] font-[450] rounded-[12px] bg-[#131313] w-full border border-gray-400 border-opacity-5 ${
          searchActive && searchText ? "rounded-b-none border" : ""
        }`}
      >
        <img className="" src={Search} alt="" />
        <input
          className="text-white placeholder-white placeholder-opacity-20 text-[15px] bg-black bg-opacity-0 w-full focus:outline-none"
          name={name}
          onFocus={() => setSearchActive(true)}
          value={searchText}
          placeholder={placeholder}
          onChange={handleChange}
        />
        <div
          className={`bg-[#131313] max-h-[40vh] overflow-y-auto no-scrollbar z-[99999] w-full py-2 px-4 bottom-0 left-0 translate-y-full border border-gray-400 border-opacity-5 text-white/40 ${
            searchActive ? "absolute" : "hidden"
          }`}
        >
          {searchText && searchResult === null && <p>No Results found ..</p>}
          {searchResult && (
            <div>
              {searchResult?.curriculum && searchResult?.curriculum?.length > 0 && (
                <div>
                  <p className="text-white/80 font-medium my-2">Curriculums</p>
                  <ul className="pl-3 text-sm flex flex-col gap-1">
                    {searchResult?.curriculum?.map((item) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={`/curriculum-details/${item._id}`}
                            className="cursor-pointer w-full"
                          >
                            {item?.title?.length > 45
                              ? item?.title?.substring(0, 45) + ".."
                              : item?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {searchResult?.testimonials && searchResult?.testimonials?.length > 0 && (
                <div>
                  <p className="text-white/80 font-medium my-2">Testimonials</p>
                  <ul className="pl-3 text-sm flex flex-col gap-1">
                    {" "}
                    {searchResult?.testimonials?.map((item) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={`/testimonialInfo/${item._id}`}
                            className="cursor-pointer w-full"
                          >
                            {item?.title?.length > 45
                              ? item?.title?.substring(0, 45) + ".."
                              : item?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {searchResult?.categories && searchResult?.categories?.length > 0 && (
                <div>
                  <p className="text-white/80 font-medium my-2">Category</p>
                  <ul className="pl-3 text-sm flex flex-col gap-1">
                    {" "}
                    {searchResult?.categories?.map((item) => {
                      return (
                        <li key={item?._id}>
                          <Link to={`/categoryInfo/${item._id}`} className="cursor-pointer w-full">
                            {item?.title?.length > 45
                              ? item?.title?.substring(0, 45) + ".."
                              : item?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {searchResult?.videos && searchResult?.videos?.length > 0 && (
                <div>
                  <p className="text-white/80 font-medium my-2">Videos</p>
                  <ul className="pl-3 text-sm flex flex-col gap-1">
                    {" "}
                    {searchResult?.videos?.map((item) => {
                      return (
                        <li key={item?._id}>
                          <Link to={`/videoInfo/${item._id}`} className="cursor-pointer w-full">
                            {item?.title?.length > 45
                              ? item?.title?.substring(0, 45) + ".."
                              : item?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
