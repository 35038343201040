import React from "react";
import CommonCarousel from "./CommonCarousel";
import { useNavigate } from "react-router-dom";

const HomeCurriculumCarousel = ({
  title,
  data = [],
  description,
  bgImage,
  id,
}) => {
  const navigate = useNavigate();

  return (
    <div className="ml-[1%] mt-[5%] pr-[2.5%]">
      <div
        className="w-full rounded-2xl px-[4%] py-[3%] bg-no-repeat bg-cover relative overflow-hidden"
        style={{
          backgroundImage: `url('${bgImage}')`,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            background: "black",
            height: "100%",
            width: "100%",
            opacity: 0.75,
          }}
        ></div>
        <div className="absolute">
          <p className="text-white text-4xl font-semibold mb-5 ">{title}</p>
          <p className="text-white text-sm lg:w-[30%] md:w-full min-h-[15vh] text-opacity-60">
            {description?.length <= 300
              ? description
              : description?.substring(0, 300)}{" "}
            <span
              onClick={() => navigate(`/curriculum-details/${id}`)}
              className="text-yellow-500 font-medium opacity-100 text-opacity-100 cursor-pointer"
            >
              More
            </span>
          </p>
        </div>
        <div className="w-full h-[25vh]"></div>
        <div className="mt-6">
          <CommonCarousel
            _id={id}
            data={data}
            seeAll={false}
            perViewImages={4}
            imageType={"landscape"}
            showItemTitle={true}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCurriculumCarousel;
