import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
import FooterBar from "./FooterBar";
import { getCategoriesAction } from "../../store/Categories/CategoriesActions";
import CategoryVideoModel from "../home/CategoryVideoModel";

import Play from "../../assets/icons/play.svg";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import GotQuestions from "./GotQuestions";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";

const CategoryInfo = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { isAuthenticated } = useAuth0();

  const [popCancle, setPopCancel] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCategoryInfoModal, setShowCategoryInfoModal] = useState(false);
  const [currentVideoDetails, setCurrentVideoDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("Book of Jonh");
  const [currentVideoData, setCurrentVideoData] = useState({
    thumbnailUrl: "",
    title: "",
    description: "",
    url: "",
  });

  const [relatedVideos, setRelatedVideos] = useState([]);

  const handleTab1 = () => {
    setActiveTab("Book of Jonh");
  };

  useEffect(() => {
    dispatch(
      getCategoriesAction({
        apiPayloadRequest: {
          _id: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setRelatedVideos([]);

            const data = res?.data[0];
            const relatedvideosData = data?.relatedvideos;
            const image =
              data?.icon_images?.filter((item) => item.type_of_url === "1")[0]?.url_link || "";

            setCurrentVideoData((prev) => ({
              ...prev,
              thumbnailUrl: image || "",
              title: data?.category_name || "",
              description: data?.category_description || "",
              url: image || "",
              // url: data?.urls[0]?.url_link || '',
            }));

            const checkValidRelatedVideos = relatedvideosData?.find((v) => v?.videoObjId);

            if (checkValidRelatedVideos) {
              relatedvideosData.forEach((item) => {
                const vidId = item?.videoObjId[0]?._id;
                const thumbnail =
                  item?.videoObjId[0]?.tumbnailurls?.find((item) => item.type_of_url === "1")
                    ?.url_link || "";
                const vidUrl =
                  item?.videoObjId[0]?.urls?.find((item) => item.type_of_url === "1")?.url_link ||
                  "";

                setRelatedVideos((prev) => [
                  ...prev,
                  {
                    _id: vidId,
                    video: vidUrl,
                    thumbnail: thumbnail,
                    title: item?.videoObjId[0]?.video_title,
                    description: item?.videoObjId[0]?.video_description,
                  },
                ]);
              });
            }
          }
        },
      })
    );
  }, [id]);

  const handleBookmark = () => {
    if (isAuthenticated) {
      // API call
    } else {
      setPopCancel(false);
    }
  };

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={popCancle}
          cancelBtn={!popCancle}
          cancelBtnHandle={() => setPopCancel(true)}
          title1={currentVideoData?.name ? currentVideoData?.name : "Book of John"}
          back={true}
          active={activeTab}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-10">
          <div className="mx-7 mt-6 h-[80vh]">
            <div
              className="w-full h-full rounded-2xl px-[4%] py-[3%] bg-no-repeat bg-cover overflow-hidden relative"
              style={{
                backgroundImage: `url('${currentVideoData?.thumbnailUrl}')`,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  background: "black",
                  height: "100%",
                  width: "100%",
                  opacity: 0.75,
                  zIndex: 2,
                }}
              ></div>
              <div className="w-full flex h-full justify-between">
                <div className="z-10 relative w-6/12 flex flex-col justify-between">
                  <p>
                    {currentVideoData?.isBookmarked ? (
                      <FaBookmark
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={handleBookmark}
                      />
                    ) : (
                      <FaRegBookmark
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={handleBookmark}
                      />
                    )}
                  </p>
                  <div className="flex flex-col">
                    <p className="text-white text-4xl font-semibold mb-5 ">
                      {currentVideoData?.title}
                    </p>
                    <p className="text-white text-sm w-full mb-5 text-opacity-60">
                      {currentVideoData?.description?.length <= 300 ? (
                        currentVideoData?.description
                      ) : (
                        <>
                          {currentVideoData?.description?.substring(0, 300)}{" "}
                          <span
                            onClick={() => setShowCategoryInfoModal(true)}
                            className="text-yellow-500 font-medium opacity-100 text-opacity-100 cursor-pointer"
                          >
                            More
                          </span>
                        </>
                      )}{" "}
                    </p>
                  </div>
                </div>
                {relatedVideos?.length > 0 && (
                  <ul className="w-4/12 overflow-y-auto no-scrollbar grid gap-3 h-full z-10 border-yellowish/60 p-4 rounded-lg bg-blackishV2 border-[0.8px]">
                    {relatedVideos?.map((item) => {
                      return (
                        <li className="flex flex-col relative rounded-lg overflow-hidden h-[28vh]">
                          <img
                            src={item?.thumbnail}
                            alt="video-item-1"
                            className="rounded-lg w-full h-full object-cover object-top"
                          />
                          <img
                            src={Play}
                            className="z-10 absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[15%] cursor-pointer"
                            alt="playBtn"
                            onClick={() => {
                              if(!isAuthenticated){
                                dispatch(setLoginHardBlocker(true));
                                return; 
                              }
                              setCurrentVideoDetails(item);
                              setShowModal(true);
                            }}
                          />
                          <div className="absolute p-3 bg-gradient-to-t from-black from-[2%] h-full w-full">
                            <div className="flex flex-col h-full w-full justify-end">
                              <p className="text-white text-xl font-semibold mb-1 overflow-hidden text-nowrap text-start overflow-ellipsis w-[95%]">
                                {item?.title?.length < 25
                                  ? item?.title
                                  : item?.title?.substring(0, 25) + ".."}
                              </p>
                              <p className="text-white text-xs w-full text-opacity-60">
                                {item?.description?.length < 50
                                  ? item?.description
                                  : item?.description?.substring(0, 50) + ".."}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <CategoryVideoModel
            isOpen={showModal}
            onRequestClose={() => {
              setShowModal((prev) => !prev);
              setCurrentVideoDetails(null);
            }}
            data={currentVideoDetails}
          />
          <ReactModal
            style={{
              overlay: {
                zIndex: 1000, // Adjust this value as needed
              },
              content: {
                zIndex: 1001, // Adjust this value as needed
              },
            }}
            className={
              "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
            }
            isOpen={showCategoryInfoModal}
            onRequestClose={() => {
              setShowCategoryInfoModal(false);
            }}
          >
            <button
              className="absolute left-4 top-4"
              onClick={() => {
                setShowCategoryInfoModal(false);
              }}
            >
              <BsX size={40} color="white" />
            </button>
            <div className="h-[80vh] w-6/12 bg-black rounded-xl flex justify-center items-center cursor-pointer relative">
              <div className="absolute bottom-0 p-10 w-full h-full object-cover rounded-xl flex flex-col">
                <p className="text-yellowish text-4xl font-semibold mb-5 ">
                  {currentVideoData?.title}
                </p>
                <p className="text-white text-lg w-full mb-5 text-opacity-60 flex-1 overflow-y-auto no-scrollbar">
                  {currentVideoData?.description}
                </p>
              </div>
            </div>
          </ReactModal>
          <GotQuestions />
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default CategoryInfo;
