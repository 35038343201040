import * as actionTypes from "../ActionTypes";

const INITIAL = {
  data: {
    languageHeadings: [],
    languagePreferred: "",
    currentLanguageTitles: [],
    loginHardBlocker: false,
  },
};

const languageReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_DATA:
      return {
        ...state,
        data: { ...state.data, languageHeadings: action.payload },
      };

    case actionTypes.LANGUAGE_PREFERRED:
      return {
        ...state,
        data: { ...state.data, languagePreferred: action.payload },
      };

    case actionTypes.LANGUAGE_CURRENT:
      return {
        ...state,
        data: { ...state.data, currentLanguageTitles: action.payload },
      };

    case actionTypes.LOGIN_HARD_BLOCKER:
      return {
        ...state,
        data: { ...state.data, loginHardBlocker: action.payload },
      };
    default:
      return state;
  }
};

export default languageReducer;
