import { useState } from "react";
import NeedPrayerModal from "./NeedPrayerModal";

const HomeNeedPrayer = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="">
        <p className="text-lg font-semibold text-white"> Need prayer?</p>
        <div className="need-prayer-bg my-5 rounded-2xl flex justify-center items-center">
          <button
            className="bg-black opacity-70 font-semibold text-yellow-500 text-opacity-60 py-5 px-20 rounded-xl uppercase text-2xl"
            onClick={() => setShowModal(true)}
          >
            Need prayer?
          </button>
        </div>
      </div>
      <NeedPrayerModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default HomeNeedPrayer;
