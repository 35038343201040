import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import { useDispatch } from "react-redux";
import LessonAndSlideComp from "./LessonAndSlideComp";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";

const CurriculumDetailsNew2 = ({ fromLink = "" }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const [activeTab, setActiveTab] = useState("Curriculum");
  const [curriculumData, setCurriculumData] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const handleTab1 = () => {
    setActiveTab("Curriculum");
  };

  useEffect(() => {
    dispatch(
      curriculumReadAction({
        apiPayloadRequest: { _id: id },
        callback: (res) => {
          if (res?.type === 1) {
            const curriculum = res?.data[0];
            setCurriculumData(curriculum);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

//   useEffect(()=> {
//     if(!isAuthenticated) {
//       dispatch(setLoginHardBlocker(true));
//     }      
// }, [isAuthenticated]);

  return (
    <div className="flex w-full overflow-hidden">
      <Navbar active={"Curriculum"} />
      <div className="ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          back={true}
          backLink={fromLink ? fromLink : "/curriculum"}
          title1={activeTab}
          active={activeTab}
          handleTab1={handleTab1}
          absolute={true}
          enableBack={true}
        />
        <div className="flex flex-col bg-black overflow-y-auto w-full gap-10 min-h-[100vh] relative overflow-auto">
          <div
            className="fixed w-full h-full bg-no-repeat bg-center bg-cover blur-md ml-4"
            style={{
              backgroundImage: `url(${curriculumData?.bg_Images[1]?.url_link})`,
            }}
          ></div>
          {curriculumData && (
            <div className="absolute top-0 w-full h-[20vh] bg-gradient-to-b from-black from-[25%] "></div>
          )}
          {curriculumData ? (
            <div className="w-full h-full bg-black bg-opacity-50 pt-[8%] p-[5%] z-10 ">
              <p className="text-white text-6xl font-bold mb-[1%]">
                {" "}
                {curriculumData?.curriculum_name}
              </p>
              <p className="opacity-70 text-xl text-white mb-[1%]">
                {curriculumData?.lessons?.length === 1
                  ? curriculumData?.lessons?.length + " Lesson"
                  : curriculumData?.lessons?.length + " Lessons"}
              </p>
              <p className="opacity-50 text-white w-[50%] mt-[1%]">
                {curriculumData?.curriculum_description}
              </p>
              {curriculumData?.lessons?.map((lesson) => (
                <LessonAndSlideComp 
                  lesson={lesson} 
                  activeItem={activeItem} 
                  setActiveItem={setActiveItem} 
                />
              ))}
            </div>
          ) : (
            <div className="w-full h-full pt-[8%] p-[5%] z-10">
              <div className="bg-zinc-600 rounded-xl w-[40%] h-[10vh] animate-pulse"></div>
              <div className="bg-zinc-600 rounded-xl w-[60%] h-[55vh] mt-[2%] animate-pulse"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurriculumDetailsNew2;
