import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import FooterBar from "../../components/common/FooterBar";
import TopCarousel from "../../components/home/TopCarousel";
import SimpleSlider from "../../components/home/SimpleSlider";
import SmallCarousel from "../../components/home/SmallCarousel";
import Top10Carousel from "../../components/home/Top10Carousel";
import BackgroundCarousel from "../../components/home/BackgroundCarousel";
import TopSwiper from "../../components/home/TopSwiper";
import BottomSwiper from "../../components/home/BottomSwiper";
import TopVideo from "../../components/home/TopVideo";

import BVFY from "../../assets/images/BVFY.png";
import Play from "../../assets/icons/play.svg";
import Background2 from "../../assets/images/background2.png";
import { useDispatch } from "react-redux";
import {
  getPickWhereLeftAction,
  getPromiseOfTheDayAction,
  layoutHomeScreenAction,
  layoutMaincarouselAction,
  layoutTop10Action,
  readPromiseAction,
} from "../../store/Home/HomeAction";
import HomeMainCarousel from "../../components/home/HomeMainCarousel";
import MobileAppDownload from "../../components/common/MobileAppDownload";
import ReactAudioPlayer from "react-audio-player";
import { useAuth0 } from "@auth0/auth0-react";
import CommonCarousel from "../../components/home/CommonCarousel";
import LongCarousel from "../../components/home/LongCarousel";
import ResponsiveCarousel from "../../components/home/ResponsiveCarousel";
import SlickCarousel from "../../components/home/SlickCarousel";
import {
  testimonialReadAction,
  testimonyOfTheDayReadAction,
} from "../../store/Testimonial/TestimonialAction";
import { useNavigate, useParams } from "react-router-dom";
import HomeCarousel from "../../components/home/HomeCarousel";
import LoadingSlides from "../../components/common/LoadingSlides";
import PromiseCarousel from "../../components/home/PromiseCarousel";
import TopSwiper2 from "../../components/testimonials/TopSwiper2";
import TransformCarousel from "../../components/home/TransformCarousel";
import HomeCurriculumCarousel from "../../components/home/HomeCurriculumCarousel";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import HomePromise from "../../components/home/HomePromise";
import CoverEffectCarousel from "../../components/home/CoverEffectCarousel";
import HomePromiseNew from "../../components/home/HomePromiseNew";
import HomeTestimony from "../../components/home/HomeTestimony";
import HomeSupportUs from "../../components/home/HomeSupportUs";
import HomeNeedPrayer from "../../components/home/NeedPrayer/HomeNeedPrayer";
import {
  addCategory,
  addCurriculum,
  addTestimonial,
  addVideos,
} from "../../store/Common/CommonAction";
import { removeDuplicacyById } from "../../utils/Utils";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (window.location.pathname === "/login") {
      navigate("/");
    }
  }, [window.location.pathname]);

  const initialTabs = ["Welcome", "Today With God"];
  const initialSeeAll = "See All";

  const [tabValues, setTabsValues] = useState(initialTabs);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");

  const [homeData, setHomeData] = useState([]);
  const [curriculumData, setCurriculumData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [promiseOfTheDay, setPromiseOfTheDay] = useState();
  const [promises, setPromises] = useState([]);
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));
  const [maincarousel, setMaincarousel] = useState([]);
  const [top10, setTop10] = useState([]);
  const [testimonyOfTheDay, setTestimonyOfTheDay] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [pinkWhereLeft, setPickWhereLeft] = useState([]);
  const [testimonials, setTestimonials] = useState([]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const restructureData = (data) => {
    return (
      data?.map((item) => ({
        _id: item?._id,
        name: item?.curriculum_name ?? item?.name,
        image: item?.url,
        title: item?.testimonial_name ?? item?.curriculum_name ?? item?.name ?? item?.title,
        subtitle: item?.curriculum_description ?? item?.subtitle,
        type: item?.type,
      })) || []
    );
  };

  const restructureDataMain = (data) => {
    return (
      data?.map((item) => ({
        _id: item._id,
        image: item?.url,
        title: item?.name,
        subtitle: item?.subtitle,
        type: item?.type,
      })) || []
    );
  };

  const restructureTestimonialData = (data) => {
    return (
      data?.map((item) => ({
        _id: item._id,
        image: item?.testomonial_rec_tumbnailUrl,
        title: item?.testimonial_name,
        subtitle: item?.subtitle,
      })) || []
    );
  };

  const getComponents = () => {
    const cData = [...curriculumData];
    const hData = [...homeData.sort((a, b) => b.order - a.order)];
    const allData = [];
    // Determine the maximum length of the two arrays
    const maxLength = Math.max(cData.length, hData.length);

    // Interleave elements from cData and hData arrays
    for (let i = 0; i < maxLength; i++) {
      if (i < cData.length) {
        allData.push(cData[i]);
      }
      if (i < hData.length) {
        allData.push(hData[i]);
      }
    }

    return (
      allData
        // .sort((a, b) => a.order - b.order)
        .map((item) => item.component)
    );
  };

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE_URL,
      });

      setCheckToken(token);
      return token;
    } catch (error) {
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    setHomeData([]);
    setCurriculumData([]);

    let videosList = [];
    let categoriesList = [];
    let curriculumList = [];
    let testimonialsList = [];

    const homeScreenActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutHomeScreenAction({
          callback: (res) => {
            const data = res?.data;
            data?.forEach((layoutItem) => {
              const restructuredData = restructureData(layoutItem?.itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
            });
            const requiredData = (data || []).map((item) => ({
              order: parseInt(item?.order),
              component:
                item?.mobile_layout === "2" ? (
                  <SlickCarousel
                    height={height}
                    width={width}
                    seeAllValue={seeAllBtn}
                    title={item?.layout_name}
                    data={restructureData(item?.itemstoDisplay)}
                  />
                ) : item?.mobile_layout === "3" ? (
                  <CoverEffectCarousel
                    height={height}
                    width={width}
                    seeAllValue={seeAllBtn}
                    title={item?.layout_name}
                    data={restructureData(item?.itemstoDisplay)}
                  />
                ) : (
                  <div className="px-[1%]">
                    <CommonCarousel
                      seeAllValue={seeAllBtn}
                      title={item?.layout_name}
                      linkTo={""}
                      dynamiclinkTo={true}
                      imageType={"landscape"}
                      showItemTitle={true}
                      data={restructureData(item?.itemstoDisplay)}
                      perViewImages={4}
                    />
                  </div>
                ),
            }));

            setHomeData((prev) => [...prev, ...requiredData]);

            resolve();
          },
        })
      );
    });

    const curriculumActionPromise = new Promise((resolve, reject) => {
      dispatch(
        curriculumReadAction({
          callback: (res) => {
            console.log("res45", res);
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data);
              restructuredData?.forEach((item) => {
                curriculumList.push(item);
              });

              const curriculumDetails = res?.data?.map((curriculum) => {
                return {
                  component: (
                    <HomeCurriculumCarousel
                      id={curriculum?._id}
                      title={curriculum?.curriculum_name}
                      description={curriculum?.curriculum_description}
                      bgImage={
                        curriculum?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]?.url_link
                      }
                      data={curriculum?.lessons?.map((item) => ({
                        _id: curriculum?._id,
                        title: item?.lesson_title,
                        subtitle: item?.lesson_title,
                        image: item?.lesson_tumbnail_urls?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link,
                      }))}
                    />
                  ),
                };
              });
              setCurriculumData(curriculumDetails);
              resolve();
            }
          },
        })
      );
    });

    const promiseOfTheDayActionPromise = new Promise((resolve, reject) => {
      dispatch(
        getPromiseOfTheDayAction({
          callback: (res) => {
            if (res?.type === 1) {
              setPromiseOfTheDay(res?.data[0]);
            }
            resolve();
          },
        })
      );
    });

    const promiseActionPromise = new Promise((resolve, reject) => {
      dispatch(
        readPromiseAction({
          callback: (res) => {
            console.log("res45", res);
            if (res?.type === 1) {
              res?.data?.map((item) => {
                setPromises((prev) => [
                  ...prev,
                  {
                    _id: item?._id,
                    title: item?.twg_title,
                    hearGodsWord: item?.hear_gods_word,
                    readGodsWord: item?.read_gods_word,
                    image: item?.bg_Images.filter((i) => i.type_of_url === "1")[0]?.url_link,
                    audio: item?.audios.filter((i) => i.type_of_url === "1")[0]?.url_link,
                    video: item?.videos.filter((i) => i.type_of_url === "1")[0]?.url_link,
                    type: "promise",
                  },
                ]);
              });
            }
            resolve();
          },
        })
      );
    });

    const testimonialActionPromise = new Promise((resolve, reject) => {
      dispatch(
        testimonialReadAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data);
              restructuredData?.forEach((item) => {
                testimonialsList.push(item);
              });
              res?.data?.map((item) => {
                setTestimonials((prev) => [
                  ...prev,
                  {
                    _id: item?._id,
                    userName: item?.user[0]?.firstName,
                    profilePic: item?.user[0]?.profilePicUrl,
                    title: item?.testimonial_name,
                    subtitle: item?.testimonial_description,
                    image: item?.testomonial_rec_tumbnailUrl,
                    video: item?.testomonial_rec_videoUrl,
                    type: "testmonial",
                  },
                ]);
              });
            }
            resolve();
          },
        })
      );
    });

    const layoutMaincarouselActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutMaincarouselAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data[0].itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
              setMaincarousel(res?.data[0].itemstoDisplay);
            }
            resolve();
          },
        })
      );
    });

    const layoutTop10ActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutTop10Action({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data[0].itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
              setTop10(
                res?.data[0].itemstoDisplay.map((item) => {
                  return {
                    _id: item?._id,
                    title: item?.name,
                    subtitle: item?.subtitle,
                    image: item?.url,
                    type: item?.type,
                  };
                })
              );
            }
            resolve();
          },
        })
      );
    });

    const testimonyOfTheDayActionPromise = new Promise((resolve, reject) => {
      dispatch(
        testimonyOfTheDayReadAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data);
              restructuredData?.forEach((item) => {
                testimonialsList.push(item);
              });
              setTestimonyOfTheDay(res.data);
            }
            resolve();
          },
        })
      );
    });

    Promise.all([
      homeScreenActionPromise,
      curriculumActionPromise,
      promiseOfTheDayActionPromise,
      promiseActionPromise,
      testimonialActionPromise,
      layoutMaincarouselActionPromise,
      layoutTop10ActionPromise,
      testimonyOfTheDayActionPromise,
    ]).then(() => {
      dispatch(addCategory(removeDuplicacyById(categoriesList)));
      dispatch(addVideos(removeDuplicacyById(videosList)));
      dispatch(addCurriculum(removeDuplicacyById(curriculumList)));
      dispatch(addTestimonial(removeDuplicacyById(testimonialsList)));
    });
  }, []);

  useEffect(() => {
    if (checkToken !== null && currentCode !== "") {
      dispatch(
        getPickWhereLeftAction({
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0]?.pickuplefts;
              data?.map((item) => {
                if (item?.categoryDetails?.length > 0) {
                  const currentData = item?.categoryDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.icon_images.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.category_name,
                      subtitle: currentData?.category_description,
                      type: "category",
                    },
                  ]);
                }

                if (item?.curriculumDetails?.length > 0) {
                  const currentData = item?.curriculumDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.curriculum_name,
                      subtitle: currentData?.curriculum_name,
                      type: "curriculum",
                    },
                  ]);
                }

                if (item?.videoDetails?.length > 0) {
                  const currentData = item?.videoDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.tumbnailurls?.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.video_title,
                      subtitle: currentData?.video_title,
                      type: "video",
                    },
                  ]);
                }
              });
            }
          },
        })
      );
    }
  }, [checkToken, currentCode]);

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        if (checkToken === null) {
          const token = await getToken();
          localStorage.setItem("token", token);
        }
      }
    };

    try {
      fetchToken();
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated, checkToken]);

  return (
    <>
      <div className="md:flex hidden">
        <div className={"flex w-full overflow-hidden"}>
          <Navbar active={"Home"} />
          <div className={"ml-[104px] flex flex-col w-full"}>
            <HeaderBar
              popCancle={true}
              tabValues={tabValues}
              active={activeTab}
              absolute={false}
              handleTabChange={handleTabChange}
              getLanguagePreferred={(code) => {
                setCurrectCode(code);
              }}
              getCurrentLanguageTitles={(titles) => {
                if (titles?.home_meta) {
                  const requiredLangData = titles?.home_meta;
                  setTabsValues([requiredLangData.welcome_lbl, requiredLangData.todaywithgod_lbl]);

                  setSeeAllBtn(requiredLangData.seeall_btn);
                } else {
                  setTabsValues(initialTabs);
                  setSeeAllBtn(initialSeeAll);
                }
              }}
            />
            <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
              {activeTab === 0 ? (
                <>
                  {maincarousel && maincarousel?.length > 0 ? (
                    <HomeCarousel data={restructureDataMain(maincarousel)} width={width} />
                  ) : (
                    <div className="p-6">
                      <div className="w-[100%] h-[75vh] animate-pulse bg-zinc-600 rounded-2xl"></div>
                      <div className="p-4 flex justify-center">
                        <div className="w-[100px] h-[10px] animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </div>
                    </div>
                  )}
                  {top10 && top10?.length > 0 && (
                    <Top10Carousel data={top10} seeAllValue={seeAllBtn} />
                  )}
                  {pinkWhereLeft && pinkWhereLeft?.length > 0 && (
                    <div className="px-4">
                      <CommonCarousel
                        seeAllValue={seeAllBtn}
                        title={"Pickup Where You Left"}
                        imageType={"landscape"}
                        linkTo={""}
                        showProgress={true}
                        data={pinkWhereLeft}
                        perViewImages={5}
                      />
                    </div>
                  )}
                  {homeData && homeData?.length > 0 ? (
                    getComponents()
                  ) : (
                    <div className="w-full px-6 flex flex-col gap-6">
                      <LoadingSlides />
                      <LoadingSlides />
                      <LoadingSlides />
                    </div>
                  )}
                  {testimonyOfTheDay?.length > 0 && (
                    <HomeTestimony
                      testimonials={testimonials}
                      backgroundImage={restructureTestimonialData(testimonyOfTheDay)[0]?.image}
                      _id={restructureTestimonialData(testimonyOfTheDay)[0]?._id}
                    />
                  )}
                  {/* {promiseOfTheDay && (
                    <div className="p-6 flex flex-col justify-center items-center">
                      <HomePromise
                        bgImage={
                          promiseOfTheDay?.bg_Images?.filter(
                            (i) => i?.type_of_url === "1"
                          )[0]?.url_link
                        }
                        hearGodsWord={promiseOfTheDay?.hear_gods_word}
                        audioFile={
                          promiseOfTheDay?.audios?.filter(
                            (i) => i?.type_of_url === "1"
                          )[0]?.url_link
                        }
                      />
                    </div>
                  )} */}
                  {promiseOfTheDay && (
                    <div className="p-6 flex flex-col justify-center items-center">
                      <HomePromiseNew
                        bgImage={
                          promiseOfTheDay?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                            ?.url_link
                        }
                        title={promiseOfTheDay?.twg_title}
                        hearGodsWord={promiseOfTheDay?.hear_gods_word}
                        readGodsWord={promiseOfTheDay?.read_gods_word}
                        audioFile={
                          promiseOfTheDay?.audios?.filter((i) => i?.type_of_url === "1")[0]
                            ?.url_link
                        }
                        promises={promises}
                      />
                    </div>
                  )}
                  <div className="grid md:grid-cols-2 gap-10 p-6">
                    <HomeSupportUs />
                    <HomeNeedPrayer />
                  </div>
                </>
              ) : (
                <>
                  <TopVideo
                    background={promiseOfTheDay?.bg_Images[1]?.url_link}
                    url={promiseOfTheDay?.videos[1]?.url_link}
                  />
                  <p className="px-6 pt-6 text-lg font-semibold text-white">Hear Gods Word</p>
                  <div className="m-6 flex">
                    <img
                      className="w-[30%] h-[35vh] rounded-lg"
                      src={promiseOfTheDay?.bg_Images[1]?.url_link}
                      alt="image not found"
                    />
                    <div className="flex flex-col gap-3 justify-between px-6 w-[70%]">
                      <p className="text-xl text-white opacity-50">
                        {promiseOfTheDay?.hear_gods_word}
                      </p>
                      <div className="flex flex-col gap-4 pt-6 mb-6">
                        <p className="text-yellow-400">Hear Gods Word</p>
                        <audio controls className="w-full">
                          <source src={promiseOfTheDay?.audios[0]?.url_link} />
                        </audio>
                      </div>
                    </div>
                  </div>
                  <p className="px-6 pt-6 text-lg font-semibold text-white">Read Gods Word</p>
                  <div className="px-6">
                    <div
                      className="mt-6 relative bg-no-repeat w-full h-[80vh] rounded-xl overflow-hidden bg-cover"
                      style={{
                        backgroundImage: `url(${promiseOfTheDay?.bg_Images[1]?.url_link})`,
                      }}
                    >
                      <div className="absolute w-full h-full bg-gradient-to-r from-black to-transparent"></div>
                      <div
                        className=" flex w-full h-full justify-center items-center p-[5%] 
                      "
                      >
                        <p className="text-white text-3xl opacity-60">
                          {promiseOfTheDay?.read_gods_word}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <FooterBar />
            </div>
          </div>
        </div>
      </div>
      <MobileAppDownload />
    </>
  );
};

export default HomePage;
