import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useNavigate } from "react-router-dom";

const customStyles = {
  overlay: {
    zIndex: 1000, // Adjust this value as needed
  },
  content: {
    zIndex: 1001, // Adjust this value as needed
  },
};

const LoginHardBlocker = ({ enableBack = false, enableGoToHome = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginHardBlocker } = useSelector((state) => state.languageReducer.data);

  return (
    <ReactModal
      style={customStyles}
      className="flex p-[5%] justify-center items-center h-[100vh] bg-black bg-opacity-50"
      isOpen={loginHardBlocker}
    >
      <div className="w-1/2 h-1/2 bg-black rounded-lg flex flex-col justify-between items-center p-[5%]">
        <p className="text-yellowish text-xl text-center font-bold mb-6">Login to Continue!</p>
        <div className="w-full flex justify-between px-[2%]">
          <button
            onClick={() => {
              dispatch(setLoginHardBlocker(false));
              if (enableGoToHome) {
                navigate("/home");
              } else if (enableBack) {
                navigate(-1);
              }
            }}
            className="text-white px-8 py-4 border border-white rounded-lg font-semibold text-lg hover:bg-white hover:text-black"
          >
            {enableGoToHome ? "Go Back Home" : "Cancel"}
          </button>

          <button
            onClick={() => {
              dispatch(setLoginHardBlocker(false));
              navigate("/login");
            }}
            className="text-white px-8 py-4 border border-white rounded-lg font-semibold text-lg hover:bg-white hover:text-black"
          >
            Sign In
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default LoginHardBlocker;
