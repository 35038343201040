import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectCoverflow } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const HomeCarousel = ({ data, title, height, width }) => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  const handleNavigate = (row) => {
    if (row?.type === "curriculum") {
      navigate(`/curriculum-details/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
    if (row?.type === "category") {
      navigate(`/categoryInfo/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
    if (row?.type === "videos") {
      navigate(`/videoInfo/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
  };

  const handleSlideChange = (swiper) => {
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 600);
    }
  }, [showAnimation]);

  return (
    <div className="px-6 relative">
      <p className="mb-6 text-lg font-semibold text-white">{title}</p>
      <Swiper
        onInit={(ev) => setSwiper(ev)}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: width * 0.7905,
          depth: width * 0.11,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="flex justify-center"
        onSlideChange={handleSlideChange}
        breakpoints={{
          640: {
            coverflowEffect: {
              stretch: width * 0.76,
              depth: width * 0.11,
            },
          },
          1024: {
            stretch: width * 0.7905,
            depth: width * 0.11,
          },
        }}
      >
        {data?.map((row, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-start pb-12">
              <div
                className=" w-[86%] aspect-[16/7] bg-cover bg-no-repeat rounded-2xl relative overflow-hidden"
                style={{
                  backgroundImage: `url('${row?.image}')`,
                }}
              >
                <div
                  className={`absolute w-full h-full bg-gradient-to-r from-black from-5% to-95% ${
                    activeIndex === index
                      ? "to-transparent opacity-50"
                      : "to-black opacity-60"
                  } `}
                ></div>
                {activeIndex === index && (
                  <div
                    className={`${
                      showAnimation ? "btn-slide3" : ""
                    } absolute bottom-[10%] w-[100%] px-[5%] text-white`}
                  >
                    <p className="lg:text-5xl md:text-2xl md:mb-2 font-semibold lg:mb-4">
                      {row?.title?.length <= 30
                        ? row?.title
                        : row?.title?.substring(0, 30) + " .."}
                    </p>
                    <p className="w-[50%] lg:text-base md:text-xs lg:mb-6 md:mb-4">
                      {row?.subtitle?.length <= 210
                        ? row?.subtitle
                        : row?.subtitle?.substring(0, 208) + " .."}
                    </p>
                    <button
                      onClick={() => handleNavigate(row)}
                      className="border border-yellowish hover:bg-yellowish hover:text-black px-[12%] lg:py-[10px] md:py-[8px] rounded-md text-yellowish font-semibold lg:text-base md:text-xs mb-1"
                    >
                      Watch Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="absolute top-[45%] z-10 opacity-20 hover:opacity-95 cursor-pointer"
        onClick={() => swiper.slidePrev()}
      >
        <FaCaretLeft size={50} color="white" />
      </div>
      <div
        className="absolute top-[45%] right-5 z-10 opacity-20 hover:opacity-95 cursor-pointer"
        onClick={() => swiper.slideNext()}
      >
        <FaCaretRight size={50} color="white" />
      </div>
    </div>
  );
};

export default HomeCarousel;
