import * as actionTypes from "../ActionTypes";

const INITIAL = {
  categories: [],
  videos: [],
  curriculum: [],
  testimonials: [],
};

const commonReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.SET_GLOBAL_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };

    case actionTypes.ADD_GLOBAL_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, ...action.payload],
      };

    case actionTypes.ADD_GLOBAL_CURRICULUM:
      return {
        ...state,
        curriculum: action.payload,
      };

    case actionTypes.SET_GLOBAL_CURRICULUM:
      return {
        ...state,
        curriculum: [...state.curriculum, ...action.payload],
      };

    case actionTypes.SET_GLOBAL_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };

    case actionTypes.ADD_GLOBAL_VIDEOS:
      return {
        ...state,
        videos: [...state.videos, ...action.payload],
      };

    case actionTypes.SET_GLOBAL_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
      };

    case actionTypes.ADD_GLOBAL_TESTIMONIALS:
      return {
        ...state,
        testimonials: [...state.testimonials, ...action.payload],
      };
    default:
      return state;
  }
};

export default commonReducer;
