import Axios from "../../utils/axios";

//Get Country data
export const getCategories = (payload) => {
  const URL = "/categories/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategoriesTestimoy = (payload) => {
  const URL = "/categories/testimony/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateCategoriesTestimoy = (payload) => {
  const URL = "/categories/testimony/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const dateleCategoriesTestimoy = (payload) => {
  const URL = "/categories/testimony/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
