import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import SmallCarousel from "../home/SmallCarousel";
import {
  ArrayOfImgs14,
  ArrayOfImgs2,
  ArrayOfImgs5,
  ArrayOfImgs6,
  ArrayOfImgs7,
} from "../../constants/NavbarConstants";
import FooterBar from "../common/FooterBar";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import CommonCarousel from "../home/CommonCarousel";

const MyDownloads = () => {
  const [activeTab, setActiveTab] = useState("My Downloads");
  const [videosList, setVideosList] = useState([]);
  const [curriculumList, setCurriculumList] = useState([]);

  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const handleTab1 = () => {
    setActiveTab("My Downloads");
  };

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setLoginHardBlocker(true));
    } else {
      dispatch(setLoginHardBlocker(false));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    try {
      const idb =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;

      if (!idb) {
        console.log("This browser doesn't support IndexedDB");
        return;
      }

      const request = idb.open("TWG", 1);

      console.log("request", request);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onupgradeneeded = function (event) {
        console.log("onupgradeneeded");
        const db = event.target.result;

        db.createObjectStore("videos", { keyPath: "_id", autoIncrement: false });
        db.createObjectStore("curriculum", { keyPath: "_id", autoIncrement: false });
      };

      request.onsuccess = function (event) {
        console.log("Database opened successfully");

        const db = event.target.result;

        console.log("db", db);

        var transaction = db.transaction(["videos"], "readwrite");

        var videos = transaction.objectStore("videos");
        // var curriculum = transaction.objectStore("curriculum");
        videos.getAll().onsuccess = (event) => {
          setVideosList(event.target.result);
        };

        return transaction.complete;
      };
    } catch (error) {
      console.error("Error storing video in IndexedDB:", error);
    }
  }, []);

  console.log("Videos", videosList);
  console.log("curriculum", curriculumList);

  return (
    <div className="flex w-full">
      <Navbar active={"My Downloads"} />
      <div className="ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          title1={"My Downloads"}
          title2={""}
          active={activeTab}
          back={true}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black flex-1 justify-between overflow-y-auto w-full">
          {videosList?.length > 0 && (
            <CommonCarousel
              title="Videos"
              data={videosList}
              seeAll={false}
              dataTitle={false}
              perViewImages={6}
              imageType={"landscape"}
              showTitle={true}
              showItemTitle={true}
              linkTo="videos"
            />
          )}
          {curriculumList?.length > 0 && (
            <CommonCarousel
              title="Curriculum"
              data={curriculumList}
              seeAll={false}
              dataTitle={false}
              perViewImages={6}
              imageType={"landscape"}
              showTitle={true}
              showItemTitle={true}
              linkTo="curriculum"
            />
          )}
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default MyDownloads;
