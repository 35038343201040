import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { BsPlayCircleFill } from "react-icons/bs";

const TestCarousel = ({ data = [], title }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / 5);

  const handleNavigate = (data) => {
    navigate(`/curriculum-details/${data?._id}`);
  };

  return (
    <div className="px-6 flex flex-col justify-center items-center w-full">
      <p className="text-xl text-white font-semibold w-full mb-4">{title}</p>
      <Carousel
        className="p-1 w-full"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data?.slice(0, numberOfLists)?.map((_, index) => (
          <div className="flex w-full gap-[1%]">
            {data?.slice(index * 4, (index + 1) * 4).map((item, i) => (
              <div
                key={i}
                onMouseOver={() => setActiveIndex(i)}
                style={{
                  backgroundImage: `url(${item?.image})`,
                }}
                className={`${
                  i === activeIndex ? `aspect-[16/9] w-2/5` : `aspect-[9/16] w-1/5`
                } bg-zinc-700 rounded-lg overflow-hidden bg-cover bg-top bg-no-repeat transition-all duration-300 hover:transition-all hover:duration-300`}
              >
                {i === activeIndex && (
                  <div className="relative w-full h-full">
                    <BsPlayCircleFill
                      onClick={() => handleNavigate(item)}
                      color="#EFBC51"
                      className="w-[20%] h-auto cursor-pointer top-[45%] left-[50%] absolute -translate-x-[50%] -translate-y-[50%]"
                    />
                    <div className="flex flex-col justify-end items-start w-full h-[35%] p-[5%] bg-gradient-to-t from-black from-[2%] absolute bottom-0">
                      <p className="text-white text-xl font-bold mb-2">
                        {" "}
                        {item?.title?.length <= 30
                          ? item?.title
                          : item?.title?.substring(0, 30) + ".."}
                      </p>
                      <p className="text-white text-xs text-opacity-60 text-start">
                        {item?.subtitle?.length <= 200
                          ? item?.subtitle
                          : item?.subtitle?.substring(0, 200) + ".."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestCarousel;
